import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";
import Paper from "@mui/material/Paper";
import { FC } from "react";
import { Icon } from "../Icon";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.background.default,
    height: "100%",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(16),
  },
  icon: {
    fontSize: "6rem",
  },
}));

interface ErrorPageProps {
  error?: string;
}

export const ErrorPage: FC<ErrorPageProps> = ({ error }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.wrapper}>
        <Icon className={classes.icon} color="error" icon="report_problem" />
        <Typography variant="h5">{error}</Typography>
      </Paper>
    </div>
  );
};
