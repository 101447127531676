import {
  createListenerMiddleware,
  ListenerEffectAPI,
  TypedStartListening,
  TypedStopListening,
} from "@reduxjs/toolkit";
import { CommonsDispatch, CommonsState } from "../@types";

export const appListenerMiddleware = createListenerMiddleware();

export const startAppListening = appListenerMiddleware.startListening as TypedStartListening<
  CommonsState,
  CommonsDispatch
>;

export const stopAppListening = appListenerMiddleware.stopListening as TypedStopListening<
  CommonsState,
  CommonsDispatch
>;

export type AppListenerEffectAPI = ListenerEffectAPI<CommonsState, CommonsDispatch>;
