import { Draft, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { AppTheme } from "../app/theme";

export type SortOrder = "asc" | "desc";
export type SortBy = "auction-house" | "start-time" | "vehicle-count" | "watchlist-count";

interface UiState {
  isAppDrawerOpen: boolean;
  theme: AppTheme;
  isFiltersPopperOpen: boolean;
}

const initialState: UiState = {
  isAppDrawerOpen: false,
  theme:
    window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light",
  isFiltersPopperOpen: false,
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    toggleAppDrawer(state: Draft<UiState>) {
      state.isAppDrawerOpen = !state.isAppDrawerOpen;
    },
    toggleTheme(state: Draft<UiState>) {
      state.theme = state.theme === "light" ? "dark" : "light";
    },
    openFiltersPopper(state: Draft<UiState>) {
      state.isFiltersPopperOpen = true;
    },
    closeFiltersPopper(state: Draft<UiState>) {
      state.isFiltersPopperOpen = false;
    },
  },
});

export const selectIsAppDrawerOpen = (state: RootState) => state.ui.isAppDrawerOpen;
export const selectTheme = (state: RootState): AppTheme => state.ui.theme;
export const selectIsFiltersPopperOpen = (state: RootState) => state.ui.isFiltersPopperOpen;

export const {
  toggleAppDrawer,
  toggleTheme,
  openFiltersPopper,
  closeFiltersPopper,
} = uiSlice.actions;

export const { name: uiReducerPath, reducer: uiReducer } = uiSlice;
