import {
  CircularProgress,
  CssBaseline,
  ErrorBoundary,
  ThemeProvider,
  makeStyles,
} from "@xcira/components";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Suspense, lazy } from "react";
import { selectTheme } from "./slices";
import { useAppSelector } from "./hooks";
import { appThemes } from "./app/theme";
import { AppTooltip } from "./components/AppTooltip/AppTooltip";

const MainView = lazy(() => import("./views/MainView"));
const NotFoundView = lazy(() => import("./views/NotFoundView"));
const AutomotiveView = lazy(() => import("./views/AutomotiveView"));

const useStyles = makeStyles()(() => ({
  loader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
}));

export const App = () => {
  const { classes } = useStyles();
  const theme = useAppSelector(selectTheme);

  return (
    <ThemeProvider theme={appThemes[theme]}>
      <CssBaseline />
      <BrowserRouter>
        <ErrorBoundary>
          <Suspense
            fallback={
              <div className={classes.loader}>
                <CircularProgress />
              </div>
            }
          >
            <Switch>
              <Route exact path="/">
                <AutomotiveView />
              </Route>
              <Route exact path="/automotive-test">
                <MainView />
              </Route>
              <Route path="*">
                <NotFoundView />
              </Route>
            </Switch>
          </Suspense>
        </ErrorBoundary>
      </BrowserRouter>
      <AppTooltip />
    </ThemeProvider>
  );
};
