import { BaseQueryApi, BaseQueryEnhancer } from "@reduxjs/toolkit/dist/query/baseQueryTypes";

export interface AuthOptions {
  saleId?: number;
}

export const queryWithAuth: BaseQueryEnhancer<unknown, object, object | void> = (
  baseQuery,
  defaultOptions
  // TODO: Type these correctly
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => async (args: any & AuthOptions, baseQueryApi: BaseQueryApi, extraOptions: any = {}) => {
  const options = {
    ...defaultOptions,
    ...extraOptions,
  };

  const { accessToken } = args;

  const headers = args.headers ?? {};

  const argsWithHeaders = {
    headers: new Headers({ ...headers }),
  };

  if (accessToken) {
    argsWithHeaders.headers.set("authorization", `Bearer ${accessToken}`);
  } else {
    throw new Error("Invalid credentials");
  }

  const enhancedArgs = {
    ...args,
    ...argsWithHeaders,
  };

  // TODO: Type these correctly
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return baseQuery(enhancedArgs, baseQueryApi, options) as any;
};
