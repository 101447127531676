import { Middleware, PayloadAction } from "@reduxjs/toolkit";
import {
  PartialAnalyticsState,
  isConfigureAnalyticsAction,
  isSendEventAction,
} from "../../slices/analytics";
import type { Analytics, CommonsDispatch } from "../../@types";

// TODO: Come back and convert this to a listenerMiddleware once Andrew upgrades all the deps in the repo
export const createAnalyticsMiddleware = <C extends object>(
  analyticsProvider: Analytics<C>
): Middleware<CommonsDispatch, PartialAnalyticsState> => {
  return () => (next) => (action: PayloadAction<object>) => {
    if (isConfigureAnalyticsAction<C>(action)) {
      const configuration = action.payload.configuration;
      const id = action.payload.id;

      if (id !== analyticsProvider.getId()) {
        return next(action);
      }

      analyticsProvider.initialize(configuration);
    } else if (isSendEventAction(action)) {
      analyticsProvider.sendEvent(action.payload);
    }

    return next(action);
  };
};
