import { IMessageEvent } from "./message.event";

interface AddAttendPayload {
  clientEventId: string;
  accessToken: string;
  ahco: string;
}

export class AddAttendEvent implements IMessageEvent<AddAttendPayload> {
  readonly name: string;
  readonly payload: AddAttendPayload;

  constructor(payload: AddAttendPayload) {
    this.name = "add-attend";
    this.payload = payload;
  }
}
