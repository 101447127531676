import { createApi } from "@reduxjs/toolkit/query/react";
import { olrQuery } from "../queryEnhancers";

const baseQuery = olrQuery({ serviceProvider: "services" });

export interface ReplicateOptions {
  username: string;
  eventId: string;
  accessToken: string;
}

export const olrServices = createApi({
  reducerPath: "services",
  baseQuery,
  endpoints: (build) => ({
    replicate: build.mutation<string, ReplicateOptions>({
      query: ({ username, eventId, accessToken }) => ({
        url: "web/auth/replicate",
        method: "POST",
        body: {
          username,
          eventid: eventId,
        },
        responseHandler: "text",
        accessToken,
      }),
      transformResponse: (response: string) => response.trim(),
    }),
  }),
});

export const {
  useReplicateMutation,
  reducer: olrServicesReducer,
  reducerPath: olrServicesReducerPath,
  endpoints: olrServiceEndpoints,
} = olrServices;
