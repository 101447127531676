import { createSlice } from "@reduxjs/toolkit";
import { entityManagerServicesEndpoints } from "../../services";
import { PartialState } from "../../@types";

type UserInterest = {
  lotWatches: string[];
  lotNotes: Record<string, string[]>;
};

type UserInterestState = Record<string, UserInterest>;

const initialState: UserInterestState = {};

const userInterest = createSlice({
  name: "userInterest",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      entityManagerServicesEndpoints.getUserDetails.matchFulfilled,
      (state, action) => {
        const { lotWatches, lotNotes } = action.payload.SaleUserActivity;
        const { accessToken } = action.meta.arg.originalArgs;
        state[accessToken] = { lotWatches, lotNotes };
      }
    );

    builder.addMatcher(
      entityManagerServicesEndpoints.addToWatchlist.matchFulfilled,
      (state, action) => {
        const { accessToken, runName } = action.meta.arg.originalArgs;
        state[accessToken]?.lotWatches?.push(runName);
      }
    );

    builder.addMatcher(
      entityManagerServicesEndpoints.removeFromWatchlist.matchFulfilled,
      (state, action) => {
        const { accessToken, runName } = action.meta.arg.originalArgs;
        const index = state[accessToken]?.lotWatches?.indexOf(runName) ?? -1;
        if (index !== -1) {
          state[accessToken]?.lotWatches?.splice(index, 1);
        }
      }
    );

    builder.addMatcher(
      entityManagerServicesEndpoints.addToLotNotes.matchFulfilled,
      (state, action) => {
        const { accessToken, runName, notes } = action.meta.arg.originalArgs;
        if (notes) {
          state[accessToken].lotNotes[runName] = [notes];
        }
      }
    );

    builder.addMatcher(
      entityManagerServicesEndpoints.removeFromLotNotes.matchFulfilled,
      (state, action) => {
        const { accessToken, runName } = action.meta.arg.originalArgs;
        const lotNotes = state[accessToken]?.lotNotes;
        if (lotNotes) {
          delete state[accessToken].lotNotes[runName];
        }
      }
    );
  },
});

type UserInterestReducerState = PartialState<typeof userInterest>;

const selectIsLotWatched = (
  state: UserInterestReducerState,
  accessToken: string,
  runName: string
) => {
  return state.userInterest[accessToken]?.lotWatches?.includes(runName) ?? false;
};

const selectLotWatches = (state: UserInterestReducerState, accessToken: string) => {
  return state.userInterest[accessToken]?.lotWatches ?? [];
};

const selectHasLotNotes = (
  state: UserInterestReducerState,
  accessToken: string,
  runName: string
) => {
  return state.userInterest[accessToken]?.lotNotes[runName]?.length > 0;
};

const selectLotNotes = (state: UserInterestReducerState, accessToken: string, runName: string) => {
  return state.userInterest[accessToken]?.lotNotes[runName];
};
export const { name: userInterestPath, reducer: userInterestReducer } = userInterest;

export { selectIsLotWatched, selectHasLotNotes, selectLotNotes, selectLotWatches };
