export class CollectionUtil {
  static shuffle<T>(list: T[]): T[] {
    return list
      .map((item) => ({ sort: Math.random(), value: item }))
      .sort((a, b) => a.sort - b.sort)
      .map((item) => item.value);
  }

  static random<T>(list: T[]): T {
    return list[Math.floor(Math.random() * list.length)];
  }

  static alphaNumericCollator = new Intl.Collator("en-US", { numeric: true });

  static alphaNumericCompare(a: string, b: string): number {
    return CollectionUtil.alphaNumericCollator.compare(a, b);
  }
}
