import { SubscriptionHandle } from "cometd";
import { IMessageEvent } from "../messaging";
import { EntityManagerQueryOptions } from "../services";
import { UiEntityRecord } from "../services/EntityManagerServices/types/EntityManager";
import { ErrorCode } from "../slices/errors";

export type MessagingConnectionStatus =
  | "new"
  | "connecting"
  | "connected"
  | "reconnecting"
  | "disconnected"
  | "failed";

export enum MessagingClientType {
  COMET = "comet",
  METEOR = "meteor",
}

type SubscriptionType<T extends MessagingClientType> = T extends MessagingClientType.COMET
  ? SubscriptionHandle
  : symbol;
type MessageType<T> = T extends MessagingClientType.METEOR ? IMessageEvent : object;

export type MessagingConnectionType = "NEW" | "RECONNECT";

export type MessagingConnectionStatusChangeListener = (
  connectionStatus: MessagingConnectionStatus
) => void;
export type MessagingErrorListener = (error: {
  errorCode: string | ErrorCode;
  accessToken?: string;
}) => void;
export type MessagingRefetchListener = () => void;

export interface IMessagingClient<T extends MessagingClientType> {
  sessionId: string;
  connectionStatus: MessagingConnectionStatus;
  servers: string[];

  onConnectionStateChange: MessagingConnectionStatusChangeListener;
  onError: MessagingErrorListener;
  onRefetch: MessagingRefetchListener;

  setServers(servers: string[]): void;
  initialize(
    entityManagerQueryOptions: EntityManagerQueryOptions,
    onConnectionStateChange: MessagingConnectionStatusChangeListener,
    onError: MessagingErrorListener,
    onRefetch: MessagingRefetchListener
  ): void | Promise<void>;
  publish(
    event: MessageType<T>,
    channel?: string,
    onMsg?: (message: MessageType<T>) => void
  ): void | Promise<void>;
  isConnected(): boolean;
  subscribe(
    uiEntityName: string,
    channel: string,
    queryOptions: EntityManagerQueryOptions,
    listener: (data: UiEntityRecord) => void
  ): SubscriptionType<T> | Promise<SubscriptionType<T>>;
  unsubscribe(
    subscription: SubscriptionType<T>,
    uiEntityName?: string,
    channel?: string,
    queryOptions?: EntityManagerQueryOptions
  ): void | Promise<void>;
  createChannelName(channel: string): string;
  updateConnectionStatus(connectionStatus: MessagingConnectionStatus): void;
  getConnectionType(): MessagingConnectionType;
}
