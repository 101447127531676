export class HostnameUtil {
  static async getAccessibleHostname(
    hostnames: string[],
    pathname: string = "",
    timeout = 5000
  ): Promise<string | null> {
    for (const hostname of hostnames) {
      try {
        const controller = new AbortController();
        const id = setTimeout(() => controller.abort(), timeout);

        const url = new URL(pathname, hostname);

        const response = await fetch(url.href, {
          method: "GET",
          signal: controller.signal,
        });

        clearTimeout(id);

        if (response.ok) {
          return hostname;
        }
      } catch {}
    }

    return null;
  }
}
