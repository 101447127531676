import { Analytics } from "../@types";
import { BrowserOptions, getCurrentHub, init, addIntegration } from "@sentry/react";
import { uniqueId } from "../lodash";

export type SentryConfiguration = BrowserOptions;
export type SentryIntegrations = BrowserOptions["integrations"];

class Sentry implements Analytics<SentryConfiguration> {
  private readonly id: string;

  constructor() {
    this.id = uniqueId();
  }

  initialize(configuration: SentryConfiguration): void {
    if (!this.isInitialized()) {
      init(configuration);
    }
  }

  sendEvent(): void {}

  getConfigurationKeys(): string[] {
    return [];
  }

  getId(): string {
    return this.id;
  }

  isInitialized(): boolean {
    return Boolean(getCurrentHub().getClient());
  }

  setIntegrations(integrations: SentryIntegrations): void {
    if (this.isInitialized() && integrations && Array.isArray(integrations)) {
      for (const integration of integrations) {
        addIntegration(integration);
      }
    }
  }
}

export const sentry = new Sentry();
