import { PartialState } from "../../@types";
import { ConnectionStatus } from "./../../comet/comet";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: ConnectionStatus = "new" as ConnectionStatus;

const cometConnection = createSlice({
  name: "cometConnection",
  initialState: initialState,
  reducers: {
    setConnectionStatus: (_state, action: PayloadAction<ConnectionStatus>) => {
      return action.payload;
    },
  },
});

type CometReducerState = PartialState<typeof cometConnection>;

const getConnectionStatus = (state: CometReducerState) => state.cometConnection as ConnectionStatus;
const selectIsConnected = (state: CometReducerState) => state.cometConnection === "connected";

export const { name: cometConnectionPath, reducer: cometConnectionReducer } = cometConnection;
export const { setConnectionStatus } = cometConnection.actions;
export { getConnectionStatus, selectIsConnected };
