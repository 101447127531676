import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  queryWithServiceDiscovery,
  ServiceProviderOptions,
} from "../queryEnhancers/QueryWithServiceDiscovery";
import type { Language } from "../EntityManagerServices/types";
import defaultResult from "../../testing/mocks/mockLanguageResponse.json";

interface Item {
  terms: Language;
  product: string;
  marketplace: string;
  company: string;
  name: string;
  langauge: string;
}

export interface TerminologyServiceResponse {
  Items: Array<Item>;
}

export interface TerminologyServiceQueryOptions {
  marketPlace: string;
  lang: string;
}

const transformResponse = (data: TerminologyServiceResponse) => {
  return {
    ...defaultResult,
    ...data?.Items[0]?.terms,
  };
};

const baseQuery = queryWithServiceDiscovery(fetchBaseQuery({ baseUrl: "/" }), {
  serviceProvider: "terminology",
} as ServiceProviderOptions);

export const terminologyService = createApi({
  reducerPath: "terminology",
  baseQuery,
  endpoints: (build) => ({
    getTerminology: build.query<Language, TerminologyServiceQueryOptions>({
      query: ({ marketPlace, lang }) => ({
        url: `p/olrwc/m/${marketPlace}?lg=${lang}`,
      }),
      transformResponse: (response: TerminologyServiceResponse) => transformResponse(response),
    }),
  }),
});

export const {
  useGetTerminologyQuery,
  reducer: terminologyServiceReducer,
  reducerPath: terminologyServiceReducerPath,
  endpoints: terminologyServiceEndpoints,
} = terminologyService;
