import {
  IMessagingClient,
  MessagingClientType,
  MessagingConnectionStatus,
  MessagingConnectionStatusChangeListener,
  MessagingConnectionType,
  MessagingErrorListener,
  MessagingRefetchListener,
} from "../@types";
import { io, Socket } from "socket.io-client";
import { UiEntityRecord } from "../services/EntityManagerServices/types/EntityManager";
import { AddAttendEvent, IMessageEvent, RemoveAttendEvent } from "./events";
import { EntityManagerQueryOptions } from "../services";

type DisconnectDescription =
  | Error
  | {
      description: string;
      context?: unknown;
    };

interface MeteorSubscription {
  id: symbol;
  connectionKey: symbol;
  listener: (data: UiEntityRecord) => void;
}

interface MeteorMessage {
  meta: {
    channelId: string;
  };
  data: string;
}

type Channel = string;
type UiEntityName = string;

export class MeteorClient implements IMessagingClient<MessagingClientType.METEOR> {
  private client: Socket;
  private subscriptions: Record<Channel, Record<UiEntityName, MeteorSubscription[]>>;
  private shouldReconnect: boolean;
  private connections: Map<symbol, EntityManagerQueryOptions>;
  private retryInterval?: NodeJS.Timeout;
  private connectionType: MessagingConnectionType;

  servers: string[];
  sessionId: string;
  connectionStatus: MessagingConnectionStatus;

  onConnectionStateChange: MessagingConnectionStatusChangeListener;
  onError: MessagingErrorListener;
  onRefetch: MessagingRefetchListener;

  constructor() {
    this.client = io({ autoConnect: false });
    this.subscriptions = {};

    this.sessionId = "";
    this.connectionStatus = "new";
    this.connectionType = "NEW";
    this.connections = new Map();
    this.servers = [];

    this.onConnectionStateChange = () => {};
    this.onError = () => {};
    this.onRefetch = () => {};

    // TODO: if comet is ever completely gone, look at using socketio native reconnect handling
    this.shouldReconnect = true;
  }

  setServers(servers: string[]): void {
    this.servers = servers;
  }

  initialize(
    queryOptions: EntityManagerQueryOptions,
    onConnectionStateChange: MessagingConnectionStatusChangeListener,
    onError: MessagingErrorListener,
    onRefetch: MessagingRefetchListener
  ): void {
    this.onConnectionStateChange = onConnectionStateChange;
    this.onError = onError;
    this.onRefetch = onRefetch;

    this.updateConnectionStatus("connecting");

    const server = this.getServerWithQueryOptions(this.servers[0], queryOptions);

    this.client = io(server, {
      withCredentials: true,
      transports: ["websocket"],
    });

    this.client.once("connect", () => this.handleInitialConnect(queryOptions));
    this.client.on("connect", () => this.handleConnect());
    this.client.on("connect_error", () => this.handleConnectError());
    this.client.on("message", (messsage) => this.handleMessage(messsage));
    this.client.on("error", this.onError);
    this.client.on("disconnect", (reason, description) =>
      this.handleDisconnect(reason, description)
    );
    this.client.io.on("reconnect_attempt", () => {
      this.handleReconnectAttempt();
    });
  }

  isConnected(): boolean {
    return this.connectionStatus === "connected";
  }

  isReconnected(connectionStatus: MessagingConnectionStatus): boolean {
    return (
      (this.connectionStatus === "disconnected" || this.connectionStatus === "reconnecting") &&
      connectionStatus === "connected"
    );
  }

  publish<T extends IMessageEvent>(event: T): Promise<void> {
    return new Promise((resolve, reject) => {
      this.client.emit(event.name, event.payload, (response: boolean) => {
        if (response) {
          resolve();
        }

        reject();
      });
    });
  }

  async subscribe(
    uiEntityName: string,
    channel: string,
    queryOptions: EntityManagerQueryOptions,
    listener: (data: UiEntityRecord) => void
  ): Promise<symbol> {
    const connectionKey = this.getConnectionKey(queryOptions);

    if (!this.connections.has(connectionKey)) {
      await this.authorizeConnection(queryOptions);
    }

    if (!this.subscriptions[channel]) {
      this.subscriptions[channel] = {};
    }

    if (!this.subscriptions[channel][uiEntityName]) {
      this.subscriptions[channel][uiEntityName] = [];
    }

    const id = Symbol();

    this.subscriptions[channel][uiEntityName].push({ id, listener, connectionKey });

    return id;
  }

  async unsubscribe(
    subscriptionId: symbol,
    uiEntityName: string,
    channel: string,
    queryOptions: EntityManagerQueryOptions
  ): Promise<void> {
    const connectionKey = this.getConnectionKey(queryOptions);

    this.removeSubscriptionFromChannel(subscriptionId, uiEntityName, channel);

    if (this.shouldRemoveConnection(connectionKey)) {
      await this.removeConnection(queryOptions);
    }
  }

  private removeSubscriptionFromChannel(
    subscriptionId: symbol,
    uiEntityName: string,
    channel: string
  ): void {
    const subscriptionIndex = this.subscriptions[channel]?.[uiEntityName]?.findIndex(
      (subscription) => subscription.id === subscriptionId
    );

    if (subscriptionIndex !== -1) {
      this.subscriptions[channel][uiEntityName].splice(subscriptionIndex, 1);
    }
  }

  createChannelName(channel: string): string {
    return channel;
  }

  updateConnectionStatus(connectionStatus: MessagingConnectionStatus): void {
    if (this.connectionStatus !== connectionStatus) {
      const isReconnect = this.isReconnected(connectionStatus);

      this.connectionStatus = connectionStatus;
      this.onConnectionStateChange(connectionStatus);

      if (isReconnect) {
        this.handleReconnect();
      }
    }
  }

  getConnectionType(): MessagingConnectionType {
    return this.connectionType;
  }

  private handleInitialConnect(queryOptions: EntityManagerQueryOptions) {
    this.connections.set(this.getConnectionKey(queryOptions), queryOptions);
  }

  private handleConnect(): void {
    this.sessionId = this.client?.id || "";

    this.updateConnectionStatus("connected");
  }

  private handleConnectError(): void {
    if (!this.shouldReconnect) {
      this.updateConnectionStatus("failed");
      this.onError({ errorCode: "RECONNECT_FAILED" });

      this.clearRetryInterval();
    }
  }

  private async handleReconnect() {
    this.clearRetryInterval();
    await this.reconnect();
    this.onRefetch();
  }

  private handleReconnectAttempt() {
    this.updateConnectionStatus("reconnecting");
  }

  private handleMessage(message: MeteorMessage): void {
    try {
      const uiEntityRecord: UiEntityRecord = JSON.parse(message.data);

      const uiEntityNames = Object.values(uiEntityRecord).map((uiEntity) => uiEntity.uiEntityName);
      const channel = message.meta.channelId;

      for (const uiEntityName of uiEntityNames) {
        const subscriptions = this.subscriptions[channel]?.[uiEntityName] ?? [];

        for (const subscription of subscriptions) {
          subscription.listener(uiEntityRecord);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  private handleDisconnect(
    reason: Socket.DisconnectReason,
    description?: DisconnectDescription
  ): void {
    this.connectionType = "RECONNECT";
    this.updateConnectionStatus("disconnected");
    console.error(reason, description);

    this.retryInterval = setInterval(() => {
      this.shouldReconnect = false;
      this.handleConnectError();
    }, 30000);
  }

  private getServerWithQueryOptions(server: string, queryOptions: EntityManagerQueryOptions) {
    const { accessToken, saleId, ahco } = queryOptions;

    const url = new URL(server);

    url.pathname = ahco;
    url.searchParams.append("accessToken", accessToken);
    url.searchParams.append("clientEventId", `${saleId}`);
    url.searchParams.append("ahco", ahco);

    return url.href;
  }

  private reconnect() {
    return Promise.all(
      Array.from(this.connections.values()).map((queryOptions) =>
        this.authorizeConnection(queryOptions)
      )
    );
  }

  private async authorizeConnection(queryOptions: EntityManagerQueryOptions): Promise<void> {
    const connectionKey = this.getConnectionKey(queryOptions);

    this.connections.set(connectionKey, queryOptions);

    const { accessToken, saleId, ahco } = queryOptions;

    try {
      await this.publish(
        new AddAttendEvent({
          ahco,
          accessToken,
          clientEventId: saleId.toString(),
        })
      );
    } catch (error) {
      console.error(error);
      this.connections.delete(connectionKey);
    }
  }

  private async removeConnection(queryOptions: EntityManagerQueryOptions): Promise<void> {
    const connectionKey = this.getConnectionKey(queryOptions);
    const { accessToken, saleId, ahco } = queryOptions;

    try {
      await this.publish(
        new RemoveAttendEvent({
          ahco,
          accessToken,
          clientEventId: saleId.toString(),
        })
      );

      this.connections.delete(connectionKey);
    } catch (error) {
      console.error(error);
    }
  }

  private shouldRemoveConnection(connectionKey: symbol): boolean {
    for (const channel in this.subscriptions) {
      for (const uiEntityName in this.subscriptions[channel]) {
        const subscriptions = this.subscriptions[channel][uiEntityName];

        if (subscriptions.some((subscription) => subscription.connectionKey === connectionKey)) {
          return false;
        }
      }
    }

    return true;
  }

  private getConnectionKey(queryOptions: EntityManagerQueryOptions): symbol {
    const { accessToken, ahco, saleId } = queryOptions;
    const connectionKey = `${accessToken}_${ahco}_${saleId}`;

    return Symbol.for(connectionKey);
  }

  private clearRetryInterval() {
    if (this.retryInterval) {
      clearInterval(this.retryInterval);
      this.shouldReconnect = true;
    }
  }
}
