import { MessagingConnectionStatus } from "../@types";
import { MessagingClient } from "../messaging";
import {
  cometServersSelector,
  entityManagerServices,
  entityManagerServicesEndpoints,
  messagingServersSelector,
} from "../services";
import {
  ErrorCode,
  error,
  selectAttendByAccessToken,
  selectCurrentAttend,
  setConnectionStatus,
} from "../slices";
import { startAppListening } from "./app-listener.middleware";

export function startMessagingClientListener() {
  startAppListening({
    matcher: entityManagerServicesEndpoints.getUserDetails.matchFulfilled,
    effect: (_action, api) => {
      api.cancelActiveListeners();

      const messagingServers = messagingServersSelector(api.getState());
      const cometServers = cometServersSelector(api.getState());
      const { accessToken } = selectCurrentAttend(api.getState());
      const { ahco, clientEventId } = selectAttendByAccessToken(api.getState(), accessToken);

      const servers = [...messagingServers, ...cometServers];

      const handleError = (errorMsg: { errorCode: string | ErrorCode; accessToken?: string }) => {
        api.dispatch(
          error({
            accessToken: errorMsg.accessToken ?? "",
            errorCode: errorMsg.errorCode as ErrorCode,
          })
        );
      };

      const handleRefetch = () => {
        api.dispatch(entityManagerServices.util.invalidateTags(["Sale", "User", "Lots", "Assets"]));
      };

      const handleConnectionStatusChange = (connectionStatus: MessagingConnectionStatus) => {
        api.dispatch(setConnectionStatus(connectionStatus));
      };

      if (servers.length) {
        MessagingClient.initialize(
          servers,
          { saleId: clientEventId, ahco, accessToken: accessToken?.trim() as string },
          handleConnectionStatusChange,
          handleError,
          handleRefetch
        );
      }

      api.unsubscribe();
    },
  });
}
