import { configureStore, ThunkAction, Action, combineReducers } from "@reduxjs/toolkit";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import {
  SentryConfiguration,
  analyticsReducer,
  analyticsReducerPath,
  appListenerMiddleware,
  authReducer,
  authReducerPath,
  cometConnectionPath,
  cometConnectionReducer,
  createAnalyticsMiddleware,
  discoveryService,
  entityManagerServices,
  olrServices,
  sentry,
  startMessagingClientListener,
  terminologyService,
  userInterestPath,
  userInterestReducer,
  createEntityManagerMiddleware,
  cubedService,
} from "@xcira/commons";
import {
  filtersReducer,
  filtersReducerPath,
  uiReducer,
  uiReducerPath,
  avReducer,
  avReducerPath,
  saleViewReducer,
  saleViewSlicePath,
} from "../slices";
import { startLotWatchListener } from "../middleware/lotWatchHandler/lotWatchHandler";

startMessagingClientListener();
startLotWatchListener();

const rootReducer = combineReducers({
  [analyticsReducerPath]: analyticsReducer,
  [authReducerPath]: authReducer,
  [cometConnectionPath]: cometConnectionReducer,
  [discoveryService.reducerPath]: discoveryService.reducer,
  [entityManagerServices.reducerPath]: entityManagerServices.reducer,
  [olrServices.reducerPath]: olrServices.reducer,
  [terminologyService.reducerPath]: terminologyService.reducer,
  [cubedService.reducerPath]: cubedService.reducer,
  [uiReducerPath]: uiReducer,
  [avReducerPath]: avReducer,
  [saleViewSlicePath]: saleViewReducer,
  [filtersReducerPath]: filtersReducer,
  [userInterestPath]: userInterestReducer,
});

const createPersistedReducer = () => {
  const persistConfig = {
    key: "automotive-bidder-client",
    storage,
    whitelist: ["auth", "ui", "saleView"],
  };

  return persistReducer<ReturnType<typeof rootReducer>>(persistConfig, rootReducer);
};

export function createStore(preloadedState?: Partial<RootState>) {
  return configureStore({
    preloadedState,
    reducer: createPersistedReducer(),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          // Ignored cause reasons: https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      })
        .concat([
          createEntityManagerMiddleware(),
          createAnalyticsMiddleware<SentryConfiguration>(sentry),
          discoveryService.middleware,
          entityManagerServices.middleware,
          terminologyService.middleware,
          olrServices.middleware,
          cubedService.middleware,
        ])
        .prepend(appListenerMiddleware.middleware),
  });
}

export const store = createStore();

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
