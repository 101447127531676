import { createSlice, Draft } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

interface AvState {
  videoPlayers: Record<string, string>;
  isSaleSelectPopperOpen: boolean;
  selectedSaleAccessToken: string | null;
}

const initialState: AvState = {
  videoPlayers: {},
  isSaleSelectPopperOpen: false,
  selectedSaleAccessToken: null,
};

const avSlice = createSlice({
  name: "av",
  initialState,
  reducers: {
    closeSaleSelectPopper(state: Draft<AvState>) {
      state.isSaleSelectPopperOpen = false;
    },
    openSaleSelectPopper(state: Draft<AvState>) {
      state.isSaleSelectPopperOpen = true;
    },
  },
});

export const selectIsSaleSelectPopperOpen = (state: RootState) => state.av.isSaleSelectPopperOpen;

export const { closeSaleSelectPopper, openSaleSelectPopper } = avSlice.actions;

export const { name: avReducerPath, reducer: avReducer } = avSlice;
