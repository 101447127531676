import { createApi } from "@reduxjs/toolkit/query/react";
import { createEntityAdapter, EntityState, createSelector, EntityId } from "@reduxjs/toolkit";
import { olrQuery } from "../queryEnhancers";
import {
  UserDetailsResponse,
  SaleDetailsResponse,
  AssetDetailsResponse,
  LotDetailsResponse,
  FollowAuctionResponse,
  UserSessionDetails,
  Block,
  Asset,
  Lot,
  UiEntity,
  UiEntityRecord,
  Bid,
  BidRejectType,
  Credit,
  SaleUserActivity,
  Auction,
  UserInterestSummary,
  UserInterestSummaryResponse,
  MarketplaceSummary,
  SaleData,
} from "./types";
import { UserUtil } from "../../utils";
import { SaleAudioVideo, SaleDetails, SaleCurrency } from "./types/SaleDetails";
import { AdminBlock, SaleMessage, SellerAction } from "./types/FollowAuction";
import { MessagingClient } from "../../messaging";
import { CommonsState } from "../../@types";
import { SaleStats, SaleStatsResponse } from "./types/SaleStats";
import { Broker, SaleUser } from "./types/UserDetails";
import { BlockHistoryEntry, BlockHistoryResponse } from "./types/BlockHistory";

const baseQuery = olrQuery({ serviceProvider: "entityManager" });
export interface EntityManagerQueryOptions {
  ahco: string;
  saleId: number | string; // TODO: make this a string everywhere
  accessToken: string;
}

export interface LogoutOptions {
  accessTokens: string[];
}

export interface BidOptions {
  bid: Partial<Bid> & EntityManagerQueryOptions;
  followAuctionOptions: FollowAuctionOptions;
}

export type FollowAuctionOptions = EntityManagerQueryOptions &
  Omit<UserSessionDetails, "uiEntityName" | "uiEntityId">;

export type MarketplaceQueryOptions = EntityManagerQueryOptions & {
  marketplaceName: string;
};

export type LanguageOptions = EntityManagerQueryOptions & {
  lang: string;
};

export type BuyerOverrideOptions = {
  runName: EntityId;
  userName: string;
} & EntityManagerQueryOptions;

export type EditInventoryOptions = {
  runName: EntityId;
  updates?: Partial<Lot>;
} & EntityManagerQueryOptions;

export type WatchListOptions = EntityManagerQueryOptions & {
  runName: string;
};

export type LotNotesOptions = EntityManagerQueryOptions & {
  runName: string;
  notes: string;
};

export type ActivateDealerOptions = EntityManagerQueryOptions & {
  dealerUserName: string;
};

export type ProcessMaxBidptions = EntityManagerQueryOptions & {
  lotId: string;
  maxAmount: number;
  clientEventId: string | number;
  userName: string;
};

export type RemoveMaxBidOptions = EntityManagerQueryOptions & {
  lotId: string;
  clientEventId: string | number;
};

export type SellOptions = EntityManagerQueryOptions & {
  clientEventId: string | number;
  lotId: string;
  sellerId: string;
  sellerAction: SellerAction;
  amount?: number;
};

export const transformResponse = <T extends UiEntityRecord>(data: UiEntityRecord) => {
  const result: UiEntityRecord = {};

  for (const key in data) {
    const uiEntity: UiEntity = data[key];
    const uiEntityName = uiEntity.uiEntityName;

    result[uiEntityName] = uiEntity;
  }

  return result as T;
};

const lotsAdapter = createEntityAdapter<Lot>({
  selectId: (lot) => lot.lotId,
  sortComparer: (a, b) => {
    if (a.lotSequence === b.lotSequence) {
      return a.runName.localeCompare(b.runName);
    }

    return Number(a.lotSequence) - Number(b.lotSequence);
  },
});

const assetsAdapter = createEntityAdapter<Asset>({
  selectId: (asset) => asset.assetId,
});

const userInterestSummaryAdapter = createEntityAdapter<UserInterestSummary>({
  selectId: (userInterestSummary) => userInterestSummary.clientEventId,
});

const marketplaceSummaryAdapter = createEntityAdapter<SaleData>({
  selectId: (saleInformation) => saleInformation.clientEventId,
});

export const entityManagerServices = createApi({
  reducerPath: "entityManager",
  baseQuery,
  tagTypes: [
    "Sale",
    "User",
    "Lots",
    "Assets",
    "UserInterestSummary",
    "MarketplaceSummary",
    "SaleStats",
    "BlockHistory",
  ],
  endpoints: (build) => ({
    getSaleDetails: build.query<SaleDetailsResponse, EntityManagerQueryOptions>({
      providesTags: ["Sale"],
      query: ({ ahco, saleId, accessToken }) => ({
        url: `rest/sale/${ahco}/${saleId}`,
        accessToken,
      }),
      transformResponse: (response: UiEntityRecord) =>
        transformResponse<SaleDetailsResponse>(response),
      onCacheEntryAdded: async (queryOptions, api) => {
        await api.cacheDataLoaded;

        const userDetailsSelector = entityManagerServicesEndpoints.getUserDetails.select(
          queryOptions
        );
        const userDetails = userDetailsSelector(api.getState());

        if (userDetails.isSuccess) {
          MessagingClient.addEntityManagerSubscription<SaleDetails>(
            "SaleDetails",
            userDetails.data.SaleUser.asyncSaleChannel.channelId,
            queryOptions,
            api,
            (saleDetails: SaleDetails) => {
              api.updateCachedData((currentSaleDetails: SaleDetailsResponse) => {
                if (currentSaleDetails.SaleDetails.saleId === saleDetails.saleId) {
                  currentSaleDetails.SaleDetails = saleDetails;
                }
              });
            }
          );

          MessagingClient.addEntityManagerSubscription<SaleDetails>(
            "SaleDetails",
            userDetails.data.SaleUser.saleChannel.channelId,
            queryOptions,
            api,
            (saleDetails: SaleDetails) => {
              api.updateCachedData((currentSaleDetails: SaleDetailsResponse) => {
                if (currentSaleDetails.SaleDetails.saleId === saleDetails.saleId) {
                  currentSaleDetails.SaleDetails = saleDetails;
                }
              });
            }
          );

          MessagingClient.addEntityManagerSubscription<SaleAudioVideo>(
            "SaleAudioVideo",
            userDetails.data.SaleUser.asyncSaleChannel.channelId,
            queryOptions,
            api,
            (saleAudioVideo: SaleAudioVideo) => {
              api.updateCachedData((currentSaleDetails: SaleDetailsResponse) => {
                if (currentSaleDetails.SaleDetails.saleId === saleAudioVideo.saleId) {
                  currentSaleDetails.SaleAudioVideo = saleAudioVideo;
                }
              });
            }
          );

          MessagingClient.addEntityManagerSubscription<SaleCurrency>(
            "SaleCurrency",
            userDetails.data.SaleUser.asyncSaleChannel.channelId,
            queryOptions,
            api,
            (saleCurrency: SaleCurrency) => {
              api.updateCachedData((currentSaleDetails: SaleDetailsResponse) => {
                if (currentSaleDetails.SaleDetails.saleId === saleCurrency.saleId) {
                  currentSaleDetails.SaleCurrency = saleCurrency;
                }
              });
            }
          );

          MessagingClient.addEntityManagerSubscription<Auction>(
            "Auction",
            userDetails.data.SaleUser.asyncSaleChannel.channelId,
            queryOptions,
            api,
            (auction: Auction) => {
              api.updateCachedData((currentSaleDetails: SaleDetailsResponse) => {
                const saleIds = auction?.saleInformationSet?.map((sale) => sale.saleId);

                if (saleIds.includes(currentSaleDetails.SaleDetails.saleId)) {
                  currentSaleDetails.Auction = auction;
                }
              });
            }
          );
        }
      },
    }),
    getUserDetails: build.query<UserDetailsResponse, EntityManagerQueryOptions>({
      providesTags: ["User"],
      query: ({ ahco, saleId, accessToken }) => ({
        url: `rest/user/${ahco}/${saleId}`,
        accessToken,
      }),
      transformResponse: (response: UiEntityRecord) =>
        transformResponse<UserDetailsResponse>(response),
      onCacheEntryAdded: async (queryOptions, api) => {
        const userDetails = await api.cacheDataLoaded;

        /**
         * Checks if brokerName exists and if it matches the brokerName in the userDetails or if the
         * saleUserId matches the saleUserId in the userDetails
         *
         * @param userDetails
         * @param userId
         * @param brokerName
         * @returns boolean
         */
        const checkBrokerNameOrUserId = (
          userDetails: UserDetailsResponse,
          userId: string,
          brokerName: string | null
        ) => {
          return (
            (brokerName !== null && userDetails.SaleUser.brokerName === brokerName) ||
            userDetails.SaleUser.id === userId
          );
        };

        MessagingClient.addBatchedEntityManagerSubscription<Credit>(
          "Credit",
          userDetails.data.SaleUser.asyncSaleChannel.channelId,
          queryOptions,
          api,
          (credit: Credit) => {
            api.updateCachedData((currentUserDetails: UserDetailsResponse) => {
              if (
                currentUserDetails.SaleUser.saleId === credit.saleId &&
                checkBrokerNameOrUserId(currentUserDetails, credit.saleUserId, credit.brokerName)
              ) {
                currentUserDetails.Credit = credit;
              }
            });
          }
        );

        MessagingClient.addBatchedEntityManagerSubscription<SaleUserActivity>(
          "SaleUserActivity",
          userDetails.data.SaleUser.asyncSaleChannel.channelId,
          queryOptions,
          api,
          (saleUserActivity: SaleUserActivity) => {
            api.updateCachedData((currentUserDetails: UserDetailsResponse) => {
              if (
                currentUserDetails.SaleUser.saleId === saleUserActivity.saleId &&
                checkBrokerNameOrUserId(
                  currentUserDetails,
                  saleUserActivity.saleUserId,
                  saleUserActivity.brokerName
                )
              ) {
                currentUserDetails.SaleUserActivity = saleUserActivity;
              }
            });
          }
        );

        MessagingClient.addBatchedEntityManagerSubscription<Broker>(
          "Broker",
          userDetails.data.SaleUser.asyncSaleChannel.channelId,
          queryOptions,
          api,
          (broker: Broker) => {
            api.updateCachedData((currentUserDetails: UserDetailsResponse) => {
              if (broker?.userId === currentUserDetails?.Broker?.userId) {
                currentUserDetails.Broker = broker;
              }
            });
          }
        );

        MessagingClient.addBatchedEntityManagerSubscription<SaleUser>(
          "SaleUser",
          userDetails.data.SaleUser.asyncSaleChannel.channelId,
          queryOptions,
          api,
          (saleUser: SaleUser) => {
            api.updateCachedData((currentUserDetails: UserDetailsResponse) => {
              if (
                saleUser?.brokerName !== null &&
                saleUser.saleId === currentUserDetails.SaleUser.saleId &&
                saleUser.brokerName === currentUserDetails.SaleUser?.brokerName
              ) {
                currentUserDetails.SaleUser = saleUser;
              }
            });
          }
        );
      },
    }),
    getUserInterestSummary: build.query<EntityState<UserInterestSummary>, MarketplaceQueryOptions>({
      providesTags: ["UserInterestSummary"],
      query: ({ marketplaceName, accessToken }) => ({
        url: `rest/marketplace/userInterestSummary/${marketplaceName}`,
        accessToken,
      }),
      transformResponse: (response: UserInterestSummaryResponse) =>
        userInterestSummaryAdapter.setAll(userInterestSummaryAdapter.getInitialState(), response),
    }),
    getMarketplaceSummary: build.query<EntityState<SaleData>, MarketplaceQueryOptions>({
      providesTags: ["MarketplaceSummary"],
      query: ({ marketplaceName, accessToken }) => ({
        url: `rest/marketplace/marketplaceSummary/${marketplaceName}`,
        accessToken,
      }),
      transformResponse: (response: MarketplaceSummary) =>
        marketplaceSummaryAdapter.setAll(
          marketplaceSummaryAdapter.getInitialState(),
          response.saleInformation
        ),
      onCacheEntryAdded: async (queryOptions, api) => {
        MessagingClient.addEntityManagerSubscription<MarketplaceSummary>(
          "MarketplaceSummary",
          //TODO: replace with actual channelId
          "channelId",
          queryOptions,
          api,
          (marketplaceSummary: MarketplaceSummary) => {
            api.updateCachedData((currentSaleInformation: EntityState<SaleData>) => {
              marketplaceSummaryAdapter.setAll(
                currentSaleInformation,
                marketplaceSummary.saleInformation
              );
            });
          }
        );
      },
    }),
    getAssetDetails: build.query<
      EntityState<Asset>,
      EntityManagerQueryOptions & { marketPlace: string }
    >({
      providesTags: ["Assets"],
      query: ({ ahco, saleId, accessToken, marketPlace }) => ({
        url: `rest/assets/${marketPlace}/${ahco}/${saleId}`,
        accessToken,
      }),
      transformResponse: (response: AssetDetailsResponse) =>
        assetsAdapter.setAll(assetsAdapter.getInitialState(), response),
      onCacheEntryAdded: async (assetDetailsQueryOptions, api) => {
        await api.cacheDataLoaded;

        const { marketPlace, ...queryOptions } = assetDetailsQueryOptions;

        const userDetailsSelector = entityManagerServicesEndpoints.getUserDetails.select(
          queryOptions
        );
        const userDetails = userDetailsSelector(api.getState());

        if (userDetails.isSuccess) {
          MessagingClient.addEntityManagerSubscription<Asset>(
            "Asset",
            userDetails.data.SaleUser.saleChannel.channelId,
            queryOptions,
            api,
            (asset: Asset) => {
              api.updateCachedData((currentAssetDetails: EntityState<Asset>) => {
                assetsAdapter.upsertOne(currentAssetDetails, asset);
              });
            }
          );
          MessagingClient.addEntityManagerSubscription<Asset>(
            "Asset",
            userDetails.data.SaleUser.asyncSaleChannel.channelId,
            queryOptions,
            api,
            (asset: Asset) => {
              if (userDetails.data.SaleUser.saleId === asset.saleId) {
                api.updateCachedData((currentAssetDetails: EntityState<Asset>) => {
                  assetsAdapter.upsertOne(currentAssetDetails, asset);
                });
              }
            }
          );
        }
      },
    }),
    getLotDetails: build.query<EntityState<Lot>, EntityManagerQueryOptions>({
      providesTags: (_result, _error, queryOptions) => {
        return [{ type: "Lots", id: queryOptions.saleId }];
      },
      query: ({ ahco, saleId, accessToken }) => ({
        url: `rest/lots/${ahco}/${saleId}`,
        accessToken,
      }),
      transformResponse: (response: LotDetailsResponse) =>
        lotsAdapter.setAll(lotsAdapter.getInitialState(), response),
      onCacheEntryAdded: async (queryOptions, api) => {
        await api.cacheDataLoaded;

        const userDetailsSelector = entityManagerServicesEndpoints.getUserDetails.select(
          queryOptions
        );
        const userDetails = userDetailsSelector(api.getState());

        if (userDetails.isSuccess) {
          MessagingClient.addBatchedEntityManagerSubscription<Lot>(
            "Lot",
            userDetails.data.SaleUser.saleChannel.channelId,
            queryOptions,
            api,
            (lot: Lot) => {
              api.updateCachedData((currentLotDetails) => {
                lotsAdapter.upsertOne(currentLotDetails, lot);
              });
            }
          );

          MessagingClient.addBatchedEntityManagerSubscription<Lot>(
            "Lot",
            userDetails.data.SaleUser.asyncSaleChannel.channelId,
            queryOptions,
            api,
            (lot: Lot) => {
              api.updateCachedData((currentLotDetails) => {
                lotsAdapter.upsertOne(currentLotDetails, lot);
              });
            }
          );
        }
      },
    }),
    getSaleStats: build.query<SaleStatsResponse, EntityManagerQueryOptions>({
      providesTags: ["SaleStats"],
      query: ({ ahco, saleId, accessToken }) => ({
        url: `rest/sale/${ahco}/${saleId}/stats`,
        accessToken,
      }),
      transformResponse: (response: UiEntityRecord) =>
        transformResponse<SaleStatsResponse>(response),
      onCacheEntryAdded: async (queryOptions, api) => {
        await api.cacheDataLoaded;

        const userDetailsSelector = entityManagerServicesEndpoints.getUserDetails.select(
          queryOptions
        );
        const userDetails = userDetailsSelector(api.getState());

        if (userDetails.isSuccess) {
          MessagingClient.addEntityManagerSubscription<SaleStats>(
            "SaleStats",
            userDetails.data.SaleUser.asyncSaleChannel.channelId,
            queryOptions,
            api,
            (saleStats: SaleStats) => {
              api.updateCachedData((currentSaleStats: SaleStatsResponse) => {
                if (currentSaleStats.SaleStats.saleId === saleStats.saleId) {
                  currentSaleStats.SaleStats = saleStats;
                }
              });
            }
          );
        }
      },
    }),
    getBlockHistory: build.query<BlockHistoryResponse, EntityManagerQueryOptions>({
      providesTags: ["BlockHistory"],
      query: ({ ahco, saleId, accessToken }) => ({
        url: `rest/blockHistory/${ahco}/${saleId}`,
        accessToken,
      }),
      onCacheEntryAdded: async (queryOptions, api) => {
        await api.cacheDataLoaded;

        const userDetailsSelector = entityManagerServicesEndpoints.getUserDetails.select(
          queryOptions
        );
        const userDetails = userDetailsSelector(api.getState());

        if (userDetails.isSuccess) {
          MessagingClient.addEntityManagerSubscription<BlockHistoryEntry>(
            "BlockHistoryEntry",
            userDetails.data.SaleUser.saleChannel.channelId,
            queryOptions,
            api,
            (blockHistoryEntry: BlockHistoryEntry) => {
              api.updateCachedData((currentBlockHistory: BlockHistoryResponse) => {
                if (
                  blockHistoryEntry?.lastClerkAction === "LOT_NAVIGATION" &&
                  currentBlockHistory.lotIds.includes(blockHistoryEntry.lotId)
                ) {
                  currentBlockHistory.blockHistoryEntries = currentBlockHistory?.blockHistoryEntries?.filter(
                    (entry) => entry.lotId !== blockHistoryEntry.lotId
                  );
                } else if (!currentBlockHistory.lotIds.includes(blockHistoryEntry.lotId)) {
                  if (currentBlockHistory.lotIds.length > 4) {
                    const removeLotId = currentBlockHistory.lotIds.shift();
                    currentBlockHistory.blockHistoryEntries = currentBlockHistory?.blockHistoryEntries?.filter(
                      (entry) => entry.lotId !== removeLotId
                    );
                  }
                  currentBlockHistory.lotIds.push(blockHistoryEntry.lotId);
                }

                currentBlockHistory.blockHistoryEntries.push(blockHistoryEntry);
              });
            }
          );
        }
      },
    }),
    followAuction: build.query<FollowAuctionResponse, FollowAuctionOptions>({
      query: (followAuctionOptions) => {
        const {
          ahco,
          saleId,
          accessToken,
          displayName,
          displayLocation,
          cometSessionId,
          lang,
          httpSessionId,
          uiFlavor,
          launchType,
          connectionState,
        } = followAuctionOptions;
        const body = {
          displayName,
          displayLocation,
          cometSessionId,
          lang,
          httpSessionId,
          uiFlavor,
          launchType,
          connectionState,
        };
        return {
          url: `rest/followAuction/${ahco}/${saleId}`,
          method: "POST",
          body,
          saleId,
          accessToken,
        };
      },
      async onQueryStarted(followAuctionOptions, api) {
        try {
          await api.queryFulfilled;

          api.dispatch({
            type: "auth/setConnectionState",
            payload: {
              accessToken: followAuctionOptions.accessToken,
              connectionState: followAuctionOptions.connectionState,
              isLoggedIn: true,
            },
          });
        } catch (error) {}
      },
      onCacheEntryAdded: async (followAuctionOptions, api) => {
        const { accessToken, ahco, saleId } = followAuctionOptions;
        const queryArgs: EntityManagerQueryOptions = { accessToken, ahco, saleId };

        const userDetailsSelector = entityManagerServicesEndpoints.getUserDetails.select(queryArgs);
        const userDetails = userDetailsSelector(api.getState());

        const { data: followAuctionResponse } = await api.cacheDataLoaded;

        if (userDetails.isSuccess) {
          MessagingClient.addEntityManagerSubscription<Bid>(
            "Bid",
            userDetails.data.SaleUser.saleChannel.channelId,
            queryArgs,
            api,
            (bid: Bid) => {
              api.updateCachedData((followAuctionResponse: FollowAuctionResponse) => {
                followAuctionResponse.Block.pendingBid = bid;
              });
            }
          );

          MessagingClient.addEntityManagerSubscription<Block>(
            "Block",
            userDetails.data.SaleUser.saleChannel.channelId,
            queryArgs,
            api,
            (block: Block) => {
              api.updateCachedData((followAuctionResponse: FollowAuctionResponse) => {
                followAuctionResponse.Block = block;
              });
            }
          );

          MessagingClient.addEntityManagerSubscription<AdminBlock>(
            "AdminBlock",
            userDetails.data.SaleUser.saleChannel.channelId,
            queryArgs,
            api,
            (adminBlock: AdminBlock) => {
              api.updateCachedData((followAuctionResponse: FollowAuctionResponse) => {
                followAuctionResponse.AdminBlock = adminBlock;
              });
            }
          );

          MessagingClient.addEntityManagerSubscription<UserSessionDetails>(
            "UserSessionDetails",
            userDetails.data.SaleUser.asyncSaleChannel.channelId,
            queryArgs,
            api,
            (userSessionDetails: UserSessionDetails) => {
              if (
                userSessionDetails.saleId === userDetails.data.SaleUser.saleId &&
                userSessionDetails.userName === userDetails.data.SaleUser.userName
              ) {
                if (userSessionDetails.connectionState === "KICKED_OUT") {
                  api.dispatch({
                    type: "errors/error",
                    payload: {
                      accessToken: followAuctionOptions.accessToken,
                      errorCode: "KICKED_OUT",
                    },
                  });
                } else if (userSessionDetails.connectionState === "RECONNECT") {
                  api.dispatch({
                    type: "errors/error",
                    payload: {
                      accessToken: followAuctionOptions.accessToken,
                      errorCode: "RECONNECT_FAILED",
                    },
                  });
                }

                if (userSessionDetails.inactive) {
                  api.dispatch(
                    entityManagerServices.endpoints.heartbeat.initiate({
                      ...followAuctionOptions,
                      clientEventId: saleId,
                      saleId: userSessionDetails.saleId,
                    })
                  );
                } else {
                  api.dispatch({
                    type: "auth/setConnectionState",
                    payload: {
                      accessToken,
                      connectionState: userSessionDetails.connectionState,
                      isLoggedIn: false,
                    },
                  });
                }
              }
            }
          );

          MessagingClient.addEntityManagerSubscription<SaleMessage>(
            "SaleMessage",
            userDetails.data.SaleUser.saleChannel.channelId,
            queryArgs,
            api,
            (saleMessage: SaleMessage) => {
              api.updateCachedData((currentFollowAuction: FollowAuctionResponse) => {
                if (currentFollowAuction.SaleMessage.saleId === saleMessage.saleId) {
                  currentFollowAuction.SaleMessage = saleMessage;
                }
              });
            }
          );

          MessagingClient.addEntityManagerSubscription<SaleMessage>(
            "SaleMessage",
            userDetails.data.SaleUser.asyncSaleChannel.channelId,
            queryArgs,
            api,
            (saleMessage: SaleMessage) => {
              api.updateCachedData((currentFollowAuction: FollowAuctionResponse) => {
                if (currentFollowAuction.SaleMessage.saleId === saleMessage.saleId) {
                  currentFollowAuction.SaleMessage = saleMessage;
                }
              });
            }
          );

          for (const lotId of followAuctionResponse.Block.lotIds) {
            if (
              UserUtil.hasBidOnLot(userDetails.data.SaleUserActivity.lotsWithBids, lotId) ||
              UserUtil.hasMaxBidOnLot(userDetails.data.SaleUserActivity.maxBids, lotId)
            ) {
              // it's bad that we use a raw object here, but we're doing it to avoid circular dependencies across reducers
              api.dispatch({
                type: "choiceAndPrivilege/commitLot",
                payload: { accessToken, lotId, userName: userDetails.data.SaleUser?.userName },
              });
              break;
            }
          }
        }
      },
      transformResponse: (response: UiEntityRecord) =>
        transformResponse<FollowAuctionResponse>(response),
    }),
    bid: build.mutation<void, BidOptions>({
      query: ({ bid: args }) => {
        const {
          saleId,
          ahco,
          lotId,
          amount,
          userName,
          biddingState,
          stateSequence,
          accessToken,
          clientEventId,
        } = args;

        const bid = {
          saleId,
          lotId,
          amount,
          userName,
          biddingState,
          stateSequence,
        };

        return {
          url: `rest/bid/${ahco}/${clientEventId}`,
          method: "POST",
          body: bid,
          saleId,
          accessToken,
        };
      },
      async onQueryStarted({ bid: args, followAuctionOptions }, { dispatch, queryFulfilled }) {
        const { saleId, lotId, amount, userName, biddingState, stateSequence } = args;

        const bid = {
          saleId,
          lotId,
          amount: amount ?? null,
          userName: userName ?? null,
          biddingState,
          stateSequence,
        };

        try {
          await queryFulfilled;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          dispatch(
            entityManagerServices.util.updateQueryData(
              "followAuction",
              followAuctionOptions,
              (draft) => {
                draft.Block.pendingBid = {
                  ...draft.Block.pendingBid,
                  ...bid,
                  status: "REJECTED",
                  rejectType: error?.error?.data?.error as BidRejectType,
                };
              }
            )
          );

          throw error;
        }
      },
    }),
    showIntent: build.mutation<void, Partial<Bid> & EntityManagerQueryOptions>({
      query: (args) => {
        const { saleId, ahco, lotId, accessToken, clientEventId } = args;
        return {
          url: `rest/showIntent/${ahco}/${clientEventId}/${lotId}`,
          method: "PUT",
          saleId,
          accessToken,
        };
      },
    }),
    activateDealer: build.mutation<void, ActivateDealerOptions>({
      query: ({ ahco, saleId, accessToken, dealerUserName }) => ({
        url: `rest/user/activateDealer/${ahco}/${saleId}/${dealerUserName}`,
        method: "PUT",
        accessToken,
      }),
    }),
    purchaseSelection: build.mutation<void, Partial<Bid> & EntityManagerQueryOptions>({
      query: (args) => {
        const {
          saleId,
          ahco,
          lotIds,
          amount,
          userName,
          biddingState,
          accessToken,
          clientEventId,
        } = args;

        const bid = {
          saleId,
          lotIds,
          amount,
          userName,
          biddingState,
        };

        return {
          url: `rest/purchaseSelection/${ahco}/${clientEventId}`,
          method: "POST",
          body: bid,
          saleId,
          accessToken,
        };
      },
    }),

    heartbeat: build.mutation<void, Partial<Bid> & EntityManagerQueryOptions>({
      query: (args) => {
        const { saleId, ahco, accessToken, clientEventId } = args;
        return {
          url: `rest/heartbeat/${ahco}/${clientEventId}`,
          method: "PUT",
          saleId,
          accessToken,
        };
      },
    }),

    logout: build.mutation<void, LogoutOptions>({
      query: ({ accessTokens }) => ({
        url: `attend/logout`,
        method: "POST",
        body: accessTokens,
        accessToken: accessTokens?.[0],
      }),
    }),

    sendFinancingApplication: build.mutation<void, EntityManagerQueryOptions>({
      query: ({ saleId, ahco, accessToken }) => ({
        url: `rest/financing/${ahco}/${saleId}`,
        method: "PUT",
        accessToken,
      }),
    }),

    buyerOverride: build.mutation<void, BuyerOverrideOptions>({
      query: ({ runName, userName, ahco, saleId, accessToken }) => ({
        url: `rest/auctionManagement/buyerOverride/${ahco}/${saleId}`,
        method: "PUT",
        accessToken,
        body: { runName, userName },
      }),
    }),

    editInventory: build.mutation<void, EditInventoryOptions>({
      query: ({ ahco, saleId, accessToken, runName, updates }) => ({
        url: `rest/auctionManagement/editInventory/${ahco}/${saleId}`,
        method: "PUT",
        accessToken,
        body: { runName, ...updates },
      }),
    }),

    addToWatchlist: build.mutation<void, WatchListOptions>({
      query: ({ ahco, saleId, accessToken, runName: lotId }) => ({
        url: `rest/userInterest/lotWatch/${ahco}/${saleId}/${lotId}`,
        method: "PUT",
        accessToken,
        responseHandler: (response) => response.text(),
      }),
    }),

    removeFromWatchlist: build.mutation<void, WatchListOptions>({
      query: ({ ahco, saleId, accessToken, runName: lotId }) => ({
        url: `rest/userInterest/lotWatch/${ahco}/${saleId}/${lotId}`,
        method: "DELETE",
        accessToken,
        responseHandler: (response) => response.text(),
      }),
    }),

    addToLotNotes: build.mutation<void, LotNotesOptions>({
      query: ({ ahco, saleId, accessToken, runName: lotId, notes }) => ({
        url: `rest/userInterest/lotNotes/${ahco}/${saleId}/${lotId}`,
        method: "PUT",
        accessToken,
        body: notes,
        responseHandler: (response) => response.text(),
      }),
    }),

    removeFromLotNotes: build.mutation<void, WatchListOptions>({
      query: ({ ahco, saleId, accessToken, runName: lotId }) => ({
        url: `rest/userInterest/lotNotes/${ahco}/${saleId}/${lotId}`,
        method: "DELETE",
        accessToken,
        responseHandler: (response) => response.text(),
      }),
    }),

    processMaxBid: build.mutation<void, ProcessMaxBidptions>({
      query: ({ ahco, saleId, accessToken, lotId, clientEventId, maxAmount, userName }) => ({
        url: `rest/maxBid/${ahco}/${clientEventId}`,
        method: "POST",
        accessToken,
        body: { saleId, lotId, maxAmount, clientEventId, userName },
      }),
    }),
    removeMaxBid: build.mutation<void, RemoveMaxBidOptions>({
      query: ({ ahco, saleId, accessToken, lotId, clientEventId }) => ({
        url: `rest/maxBid/${ahco}/${clientEventId}/${saleId}/${lotId}`,
        method: "DELETE",
        accessToken,
      }),
    }),
    sell: build.mutation<void, SellOptions>({
      query: ({
        ahco,
        accessToken,
        clientEventId,
        lotId,
        sellerId,
        sellerAction,
        amount,
        saleId,
      }) => ({
        url: `rest/sell/${ahco}/${saleId}`,
        method: "PUT",
        accessToken,
        body: { sellerId, sellerAction, clientEventId, lotId, amount },
      }),
    }),
  }),
});

export const {
  useGetSaleDetailsQuery,
  useGetUserDetailsQuery,
  useGetUserInterestSummaryQuery,
  useGetMarketplaceSummaryQuery,
  useGetAssetDetailsQuery,
  useGetLotDetailsQuery,
  useGetSaleStatsQuery,
  useGetBlockHistoryQuery,
  useFollowAuctionQuery,
  useLogoutMutation,
  useBidMutation,
  useShowIntentMutation,
  usePurchaseSelectionMutation,
  useHeartbeatMutation,
  useSendFinancingApplicationMutation,
  useBuyerOverrideMutation,
  useEditInventoryMutation,
  useAddToWatchlistMutation,
  useRemoveFromWatchlistMutation,
  useAddToLotNotesMutation,
  useRemoveFromLotNotesMutation,
  useActivateDealerMutation,
  useProcessMaxBidMutation,
  useRemoveMaxBidMutation,
  useSellMutation,
  reducer: entityManagerServicesReducer,
  reducerPath: entityManagerServicesReducerPath,
  endpoints: entityManagerServicesEndpoints,
} = entityManagerServices;

export { lotsAdapter, assetsAdapter, userInterestSummaryAdapter, marketplaceSummaryAdapter };

export const lotsSelectors = lotsAdapter.getSelectors();
export const assetsSelectors = assetsAdapter.getSelectors();
export const userInterestSelectors = userInterestSummaryAdapter.getSelectors();
export const marketPlaceSummarySelectors = marketplaceSummaryAdapter.getSelectors();
export const userInterestSummarySelectors = userInterestSummaryAdapter.getSelectors();

const selectQueryOptions = (
  state: CommonsState,
  queryOptions: EntityManagerQueryOptions
): [CommonsState, EntityManagerQueryOptions] => [state, queryOptions];

export const selectIsUserDetailsLoaded = createSelector(
  [selectQueryOptions],
  ([state, queryOptions]) => {
    const { status } = entityManagerServicesEndpoints.getUserDetails.select(queryOptions)(state);

    return status === "fulfilled";
  }
);
