import { blockUpdate, selectIsLotWatched, startAppListening } from "@xcira/commons";
import { toast } from "@xcira/components";

export function startLotWatchListener() {
  startAppListening({
    actionCreator: blockUpdate,
    effect: async (action, listenerApi) => {
      const state = listenerApi.getState();
      const activeLotId = action.payload.activeLotId;

      if (activeLotId === null) {
        return;
      }
      const isLotWatched = selectIsLotWatched(state, action.meta.accessToken, activeLotId);
      if (isLotWatched) {
        toast("Your watched lot is on the block!", { toastId: activeLotId });
      }
    },
  });
}
