import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { removeAttend } from "../auth";

const initialState: ApiError[] = [];

type ErrorCode =
  | "DUPLICATE_LOGIN"
  | "UNAUTHORIZED_USER"
  | "SALE_NOT_FOUND"
  | "USER_SESSION_NOT_FOUND"
  | "SALE_USER_NOT_FOUND"
  | "SALE_USER_NOT_INITIALIZED"
  | "FOUND_ONE_OR_MORE_NULL_FIELDS_IN_REQUEST"
  | "USER_SESSION_TIMEOUT"
  | "RECONNECT_FAILED"
  | "STARTUP_ERROR"
  | "INVALID_REVIEW_STATUS";

interface ErrorParams {
  accessToken: string;
  errorCode: ErrorCode;
}

export interface ApiError extends ErrorParams {
  timestamp: number;
}

const errors = createSlice({
  name: "errors",
  initialState,
  reducers: {
    error: (state, action: PayloadAction<ErrorParams>) => {
      state.unshift({ ...action.payload, timestamp: Date.now() });
    },
    dismissError: (state) => {
      state.shift();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(removeAttend, (state, action) => {
      return state.filter((error) => error.accessToken !== action.payload);
    });
  },
});

export type { ErrorCode, ErrorParams };
export const { name: errorReducerPath, reducer: errorReducer } = errors;
export const { error, dismissError } = errors.actions;
