import { RootState } from "../app/store";
import { SortBy, SortOrder } from "./ui.slice";
import { createSlice } from "@reduxjs/toolkit";

interface FiltersState {
  addLanesOrder: SortOrder;
  addLanesSortBy: SortBy;
  addLanesLocationFilter: string;
}

const initialState: FiltersState = {
  addLanesOrder: "asc",
  addLanesSortBy: "auction-house",
  addLanesLocationFilter: "",
};

const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setAddLanesOrder(state, action) {
      state.addLanesOrder = action.payload;
    },
    setAddLanesSortBy(state, action) {
      state.addLanesSortBy = action.payload;
    },
    setAddLanesLocationFilter(state, action) {
      state.addLanesLocationFilter = action.payload;
    },
  },
});

export const selectAddLanesOrder = (state: RootState) => state.filters.addLanesOrder;
export const selectAddLanesSortBy = (state: RootState) => state.filters.addLanesSortBy;
export const selectAddLanesLocationFilter = (state: RootState) =>
  state.filters.addLanesLocationFilter;

export const {
  setAddLanesOrder,
  setAddLanesSortBy,
  setAddLanesLocationFilter,
} = filtersSlice.actions;

export const { name: filtersReducerPath, reducer: filtersReducer } = filtersSlice;
