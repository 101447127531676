import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { queryWithServiceDiscovery } from "..";
import { CompanyUser } from "./types/CompanyUser";
import { Condition, Media } from "./types";
import { Inspection } from "./types/Inspection";
import { EntityId, EntityState, createEntityAdapter } from "@reduxjs/toolkit";

const baseQuery = queryWithServiceDiscovery(fetchBaseQuery({ baseUrl: "/" }), {
  serviceProvider: "cubed",
});

export interface Range {
  lower: number;
  upper: number;
}

export interface Filter {
  field: string;
  value?: string | boolean | number;
  values?: string[];
  filterType: "QUERY" | "TERM" | "TERMS" | "RANGE" | "EXISTS" | "MISSING";
  boolType: "MUST" | "MUST_NOT" | "SHOULD";
}

export interface MediaQueryOptions {
  filters: Filter[];
  range: Range;
}

export interface CompanyUserAvatarOptions {
  userId: number;
}

export interface InspectionQueryOptions {
  assetId: string;
}

export interface ConditionQueryOptions {
  clientAssetId: string;
  range?: Range;
}

export interface ConditionMediaQueryOptions {
  conditionIds: EntityId[];
}

const conditionsAdapter = createEntityAdapter<Condition>({
  selectId: (condition) => condition.id,
});

const conditionMediaAdapter = createEntityAdapter<Media>({
  selectId: (media) => media.entityId,
});

export function isMeta(object: unknown): object is { request: Request; response: Response } {
  return (
    typeof object === "object" && object != null && "request" in object && "response" in object
  );
}

export const cubedService = createApi({
  reducerPath: "cubed",
  baseQuery,
  endpoints: (build) => ({
    getInspection: build.query<Inspection, InspectionQueryOptions>({
      query: ({ assetId }) => ({
        url: "services/inspection",
        credentials: "include",
        headers: {
          Range: "items=0-1",
          Filters: JSON.stringify([
            {
              field: "assetId",
              value: assetId,
              values: null,
              filterType: "TERM",
              boolType: "MUST",
            },
          ]),
        },
      }),
      transformResponse: (response: Inspection[]) => response[0],
    }),
    getConditions: build.query<EntityState<Condition>, ConditionQueryOptions>({
      //@ts-expect-error - Need to upgrade redux toolkit to fix this
      queryFn: async (
        { clientAssetId: assetId }: ConditionQueryOptions,
        _api,
        _extra,
        baseQuery
      ) => {
        const response = await baseQuery({
          method: "GET",
          url: "services/condition?sort=-id",
          credentials: "include",
          headers: {
            Filters: JSON.stringify([
              {
                field: "assetId",
                value: assetId,
                values: null,
                filterType: "TERM",
                boolType: "MUST",
              },
            ]),
          },
        });

        if (response.data && isMeta(response.meta)) {
          const total = Number(response.meta.response.headers?.get("Content-Range")?.split("/")[1]);
          let condition = response.data as Condition[];

          if (total > condition.length) {
            const restOfConditions = await baseQuery({
              method: "GET",
              url: "services/condition?sort=-id",
              credentials: "include",
              headers: {
                Range: `items=${condition.length}-${total}`,
                Filters: JSON.stringify([
                  {
                    field: "assetId",
                    value: assetId,
                    values: null,
                    filterType: "TERM",
                    boolType: "MUST",
                  },
                ]),
              },
            });
            condition = [...condition, ...(restOfConditions.data as Condition[])];
          }
          response.data = conditionsAdapter.setAll(conditionsAdapter.getInitialState(), condition);
        }
        return response;
      },
    }),
    getConditionMedia: build.query<EntityState<Media>, ConditionMediaQueryOptions>({
      query: ({ conditionIds }) => ({
        url: "services/media",
        credentials: "include",
        headers: {
          Range: `items=0-${conditionIds.length ?? 0}`,
          Filters: JSON.stringify([
            {
              field: "entityId",
              value: null,
              values: conditionIds,
              filterType: "TERMS",
              boolType: "MUST",
            },
            {
              field: "entityType",
              value: "condition",
              values: null,
              filterType: "TERM",
              boolType: "MUST",
            },
          ]),
        },
      }),
      transformResponse: (response: Media[]) =>
        conditionMediaAdapter.setAll(conditionMediaAdapter.getInitialState(), response),
    }),
    getCompanyUser: build.query<CompanyUser, null>({
      query: () => ({
        url: "services/companyUser/authenticate",
        credentials: "include",
      }),
    }),
    getMedia: build.query<Media[], MediaQueryOptions>({
      query: ({ filters, range }) => ({
        url: "services/media",
        credentials: "include",
        headers: {
          Range: `items=${range.lower}-${range.upper}`,
          Filters: JSON.stringify(filters),
        },
      }),
    }),
    getCompanyUserAvatar: build.query<Media, CompanyUserAvatarOptions>({
      query: ({ userId }) => ({
        url: `services/media`,
        credentials: "include",
        headers: {
          Range: `items=0-0`,
          Filters: JSON.stringify([
            {
              field: "entityType",
              value: "USER",
              filterType: "TERM",
              boolType: "MUST",
            },
            {
              field: "entityId",
              value: userId,
              filterType: "TERM",
              boolType: "MUST",
            },
            {
              field: "primary",
              value: true,
              filterType: "TERM",
              boolType: "MUST",
            },
          ]),
        },
      }),
      transformResponse: (response: Media[]) => response[0],
    }),
  }),
});

export const {
  useGetCompanyUserQuery,
  useGetCompanyUserAvatarQuery,
  useGetMediaQuery,
  useGetInspectionQuery,
  useGetConditionsQuery,
  useGetConditionMediaQuery,
  reducer: cubedServiceReducer,
  reducerPath: cubedServiceReducerPath,
  endpoints: cubedServiceEndpoints,
} = cubedService;

export { conditionsAdapter, conditionMediaAdapter };

export const conditionsSelectors = conditionsAdapter.getSelectors();
export const conditionMediaSelectors = conditionMediaAdapter.getSelectors();
