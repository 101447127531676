import { FC } from "react";
import {
  default as MuiCircularProgress,
  CircularProgressProps,
} from "@mui/material/CircularProgress";

export const CircularProgress: FC<CircularProgressProps> = ({ ...props }) => {
  return <MuiCircularProgress {...props} />;
};

export type { CircularProgressProps };
