import { createSlice, PayloadAction, createAction } from "@reduxjs/toolkit";
import { PartialState } from "../../@types";

export type AnalyticsConfigPayload<C> = {
  configuration: C;
  id: string;
};

type AnalyticsState = Record<never, never>;

const initialState: AnalyticsState = {};

const analytics = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    configureAnalytics: () => {
      return initialState;
    },
  },
});

export const { reducer: analyticsReducer, name: analyticsReducerPath } = analytics;
export const configureAnalytics = createAction<AnalyticsConfigPayload<object>>(
  "analytics/configureAnalytics"
);
export const sendEvent = createAction<object>("analytics/sendEvent");

export type PartialAnalyticsState = PartialState<typeof analytics>;
export type SendEventAction = PayloadAction<unknown, ReturnType<typeof sendEvent>["type"]>;
export type ConfigureAnalyticsAction<C> = PayloadAction<
  AnalyticsConfigPayload<C>,
  ReturnType<typeof configureAnalytics>["type"]
>;

export const isConfigureAnalyticsAction = <C>(
  action: PayloadAction<unknown>
): action is ConfigureAnalyticsAction<C> => {
  return action?.type === configureAnalytics.toString();
};

export const isSendEventAction = (action: PayloadAction<unknown>): action is SendEventAction => {
  return action?.type === sendEvent.toString();
};
