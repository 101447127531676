import { IMessageEvent } from "./message.event";

interface RemoveAttendPayload {
  clientEventId: string;
  accessToken: string;
  ahco: string;
}

export class RemoveAttendEvent implements IMessageEvent<RemoveAttendPayload> {
  readonly name: string;
  readonly payload: RemoveAttendPayload;

  constructor(payload: RemoveAttendPayload) {
    this.name = "remove-attend";
    this.payload = payload;
  }
}
