import {
  IMessagingClient,
  MessagingClientType,
  MessagingConnectionStatusChangeListener,
  MessagingConnectionType,
  MessagingErrorListener,
  MessagingRefetchListener,
} from "../@types";
import { UiEntity, UiEntityRecord } from "../services/EntityManagerServices/types";
import { CometClient } from "./comet";
import { MeteorClient } from "./meteor";
import { batch } from "react-redux";
import { QueryCacheLifecycleApi } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { IMessageEvent } from "./events";
import { CollectionUtil } from "../utils";
import { BaseQueryFn } from "@reduxjs/toolkit/dist/query";
import { EntityManagerQueryOptions } from "../services";

class MessagingClient {
  private client?: IMessagingClient<MessagingClientType.COMET | MessagingClientType.METEOR>;
  private servers: string[];
  private isInitialized: boolean;

  clientType?: MessagingClientType;

  constructor() {
    this.servers = [];
    this.isInitialized = false;
  }

  setServers(servers: string[]): void {
    this.client?.setServers(servers);
  }

  async initialize(
    servers: string[],
    queryOptions: EntityManagerQueryOptions,
    onConnectionStateChange: MessagingConnectionStatusChangeListener,
    onError: MessagingErrorListener,
    onRefetch: MessagingRefetchListener
  ): Promise<void> {
    if (!this.isInitialized) {
      this.servers = servers;

      this.clientType = this.determineClientType();

      const selectedServers = this.getServersByClientType();

      switch (this.clientType) {
        case MessagingClientType.COMET:
          this.client = new CometClient();
          break;
        case MessagingClientType.METEOR:
          this.client = new MeteorClient();
          break;
        default:
          throw new Error("Invalid messaging client type");
      }

      this.client.setServers(selectedServers);

      await this.client.initialize(queryOptions, onConnectionStateChange, onError, onRefetch);

      this.isInitialized = true;
    }
  }

  sessionId(): string {
    return this.client?.sessionId ?? "";
  }

  addEntityManagerSubscription<EntityType extends UiEntity>(
    uiEntityName: string,
    channel: string,
    queryOptions: EntityManagerQueryOptions,
    api: QueryCacheLifecycleApi<unknown, BaseQueryFn, unknown>,
    listener: (uiEntity: EntityType) => void
  ): Promise<void> {
    return this.handleSubscription(async () => {
      if (!this.client) {
        return;
      }

      const subscription = await this.client.subscribe(
        uiEntityName,
        this.client.createChannelName(channel),
        queryOptions,
        (uiEntityRecord: UiEntityRecord) => {
          const uiEntity = this.getEntity<EntityType>(uiEntityRecord, uiEntityName);

          if (uiEntity) {
            listener(uiEntity);
          }
        }
      );

      await api.cacheEntryRemoved;

      this.client.unsubscribe(subscription, uiEntityName, channel, queryOptions);
    });
  }

  addBatchedEntityManagerSubscription<EntityType extends UiEntity>(
    uiEntityName: string,
    channel: string,
    queryOptions: EntityManagerQueryOptions,
    api: QueryCacheLifecycleApi<unknown, BaseQueryFn, unknown>,
    listener: (uiEntity: EntityType) => void,
    debounceTime: number = 300
  ): Promise<void> {
    return this.handleSubscription(async () => {
      if (!this.client) {
        return;
      }

      let callbackMap: Record<string, () => void> = {};
      let timer: ReturnType<typeof setTimeout>;

      const subscriptionId = await this.client.subscribe(
        uiEntityName,
        this.client.createChannelName(channel),
        queryOptions,
        (msg: UiEntityRecord) => {
          const entity = this.getEntity<EntityType>(msg, uiEntityName);

          if (entity) {
            if (timer) {
              clearTimeout(timer);
            }

            callbackMap[entity.uiEntityId] = () => listener(entity);

            timer = setTimeout(() => {
              batch(() => {
                Object.values(callbackMap).forEach((callback) => callback());
              });

              callbackMap = {};
            }, debounceTime);
          }
        }
      );

      await api.cacheEntryRemoved;

      this.client.unsubscribe(subscriptionId, uiEntityName, channel, queryOptions);
    });
  }

  publish(
    event: object | IMessageEvent,
    channel?: string,
    onMsg?: (message: object) => void
  ): void {
    this.client?.publish(event, channel, onMsg);
  }

  getConnectionType(): MessagingConnectionType {
    if (this.client) {
      return this.client.getConnectionType();
    }

    return "NEW";
  }

  private getEntity<EntityType extends UiEntity>(
    uiEntityRecord: UiEntityRecord,
    uiEntityName: string
  ): EntityType {
    return Object.values(uiEntityRecord).find(
      (uiEntity: UiEntity) => uiEntity.uiEntityName === uiEntityName
    ) as EntityType;
  }

  private getServersByClientType(): string[] {
    const cometServers = this.servers.filter((server) => server.includes("comet"));
    const meteorServers = this.servers.filter(
      (server) => server.includes("meteor") || server.includes("localhost")
    );

    if (this.clientType === MessagingClientType.METEOR) {
      return meteorServers;
    }

    return cometServers;
  }

  private determineClientType(): MessagingClientType {
    const meteorServers = this.servers.filter(
      (server) => server.includes("meteor") || server.includes("localhost")
    );

    const randomServer = CollectionUtil.random(this.servers);

    if (meteorServers.includes(randomServer)) {
      return MessagingClientType.METEOR;
    }

    return MessagingClientType.COMET;
  }

  private async handleSubscription(subscribe: () => Promise<void>) {
    if (this.client && this.client.isConnected()) {
      await subscribe();
    } else {
      return new Promise<void>((resolve) => {
        setTimeout(() => resolve(this.handleSubscription(subscribe)), 250);
      });
    }
  }
}

const messagingClient = new MessagingClient();

export { messagingClient as MessagingClient };
