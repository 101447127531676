import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  retry,
} from "@reduxjs/toolkit/query/react";
import { createSelector } from "@reduxjs/toolkit";
import { SentryConfiguration } from "../../sentry";
import { HostnameUtil } from "../../utils";

export interface Resources {
  sentry: SentryConfiguration;
  services: string;
  terminology: string;
  entityManager: string;
  cloudServices: string;
  cubed: string;
  cometd: string[];
  messaging: string[];
  segment: {
    writeKey: string;
  };
  cart: string;
}

interface Item {
  resources: Resources;
  id: string;
  host: string;
}

export interface DiscoveryServiceResponse {
  Items: Array<Item>;
}

export const items = {
  Items: [
    {
      resources: {
        services: "https://services-rba8.auctionsolutions.com",
        entityManager: "http://entitymanager-rba8.auctionsolutions.com",
        cometd: ["https://cometd2-webclient.rba8.auctionsolutions.com/comet"],
        messaging: ["https://meteor-rbad.xcira.cloud"],
        terminology: "https://terminology-sandbox.xcira.cloud",
        cubed: "https://nexcira.com",
      },
      id: "56a18e4e-2904-46be-a984-8a2969ada26b",
      host: "localhost:3000",
    },
  ],
  Count: 1,
  ScannedCount: 1,
};

export const mockDiscoveryBaseQuery = (): BaseQueryFn<string, unknown, unknown> => async () => {
  return {
    data: items,
  };
};

const discoveryHostnames = {
  prod: ["https://discovery.xcira.cloud"],
  preprod: ["https://discovery-preprod.xcira.cloud"],
  sandbox: ["https://discovery-sandbox.xcira.cloud"],
};

const environments = ["prod", "preprod", "sandbox"] as const;
const regions = ["us-east-1", "us-west-2"] as const;

environments.forEach(
  (env) =>
    (discoveryHostnames[env] = discoveryHostnames[env].concat(
      regions.map((region) => {
        const envSuffix = env === "prod" ? "" : `-${env}`;

        return `https://${region}-discovery${envSuffix}.xcira.cloud`;
      })
    ))
);

const isUsingEnv = (...envs: string[]) =>
  envs.some((env) => window.location.host.match(new RegExp(`^${env}[-.:]`)));

export const getCurrentEnv = () => {
  if (
    isUsingEnv("sandbox", "localhost") ||
    window.location.host.includes("cloudfront") ||
    import.meta.env.MODE === "development"
  ) {
    return "sandbox";
    //TODO: Remove liveauction.nexcira.com once ci domain has been changed
  } else if (
    isUsingEnv("ci", "qa", "uat", "liveauction-test") ||
    window.location.host.includes("liveauction.nexcira.com")
  ) {
    return "preprod";
  } else {
    return "prod";
  }
};

export const discoveryServiceFetchBaseQuery: BaseQueryFn = async (
  args: string | FetchArgs,
  api,
  extraOptions
) => {
  const env = getCurrentEnv();

  const discoveryHostname = await HostnameUtil.getAccessibleHostname(
    discoveryHostnames[env],
    "health"
  );

  if (!discoveryHostname) {
    const error: FetchBaseQueryError = {
      status: "FETCH_ERROR",
      error: "Could not connect to discovery service",
    };

    return error;
  }

  return fetchBaseQuery({ baseUrl: discoveryHostname })(args, api, extraOptions);
};

const baseQuery =
  import.meta.env.MODE === "test"
    ? mockDiscoveryBaseQuery()
    : retry(discoveryServiceFetchBaseQuery, {
        maxRetries: 5,
      });

export const discoveryService = createApi({
  reducerPath: "discovery",
  baseQuery,
  endpoints: (build) => ({
    getServices: build.query<DiscoveryServiceResponse, string>({
      query: () => `/host/${window.location.host}`,
    }),
  }),
});

export const {
  useGetServicesQuery,
  reducer: discoveryServiceReducer,
  reducerPath: discoveryServiceReducerPath,
  endpoints: discoveryServiceEndpoints,
  middleware: discoveryServiceMiddleware,
} = discoveryService;

export const discoveryServiceServerQuerySelector = discoveryService.endpoints.getServices.select(
  "/"
);
export const serversSelector = createSelector(
  discoveryServiceServerQuerySelector,
  (queryState) => queryState?.data?.Items?.[0]?.resources ?? ({} as Resources)
);

// TODO: should this be selecting based on messaging instead of cometd?
/**
 * @deprecated
 */
export const cometServersSelector = createSelector(
  serversSelector,
  (servers) => servers?.cometd?.filter((server: string) => server.length > 0) ?? []
);

export const messagingServersSelector = createSelector(
  serversSelector,
  (servers) => servers?.messaging ?? []
);
