import { createAction, Middleware } from "@reduxjs/toolkit";
import type { EntityState } from "@reduxjs/toolkit";
import type {
  Block,
  Lot,
  Asset,
  UiEntity,
  SaleDetails,
} from "../../services/EntityManagerServices/types";
import { entityManagerServices, lotsSelectors } from "../../services";
import type { EntityManagerQueryOptions } from "../../services";
import { LotUtil } from "../../utils";
import { CommonsDispatch, CommonsState } from "../../@types";

type CometUpdate = ReturnType<typeof entityManagerServices.internalActions.queryResultPatched>;

type EntityManagerState = {
  [key in typeof entityManagerServices["reducerPath"]]: ReturnType<
    typeof entityManagerServices["reducer"]
  >;
};

function getUiEntity(action: CometUpdate) {
  return action.payload?.patches?.[0]?.value as UiEntity;
}

function isUiEntity(action: CometUpdate): action is CometUpdate {
  const uiEntity = getUiEntity(action);

  return uiEntity !== null && uiEntity?.uiEntityName != null;
}

export function getPriorityBidLotId(
  state: EntityManagerState,
  block: Block,
  queryOptions: EntityManagerQueryOptions
) {
  const userDetailsSelector = entityManagerServices.endpoints.getUserDetails.select(queryOptions);
  const userDetails = userDetailsSelector(state);

  const lotIdsWithMaxBids = Object.keys(
    userDetails?.data?.SaleUserActivity?.maxBids ?? {}
  ).filter((lotId) => block?.lotIds?.includes(lotId));

  const lotDetailsSelector = entityManagerServices.endpoints.getLotDetails.select(queryOptions);
  const lotDetails = lotDetailsSelector(state);

  if (lotDetails.data) {
    const lotState = lotDetails.data as EntityState<Lot>;

    const validLotIdsWithMaxBids = lotIdsWithMaxBids
      .map((lotId) => lotsSelectors.selectById(lotState, lotId))
      .filter((lot) => lot && LotUtil.isUnsold(lot) && lot.maxBidAllowed)
      .map((lot) => lot?.lotId)
      .filter(Boolean) as string[];

    return validLotIdsWithMaxBids[0] ?? null;
  }

  return null;
}

export function getUserName(state: EntityManagerState, queryOptions: EntityManagerQueryOptions) {
  const userDetailsSelector = entityManagerServices.endpoints.getUserDetails.select(queryOptions);
  const userDetails = userDetailsSelector(state);

  return userDetails?.data?.SaleUser?.userName || "";
}

export function getSaleId(state: EntityManagerState, queryOptions: EntityManagerQueryOptions) {
  const saleDetailsSelector = entityManagerServices.endpoints.getSaleDetails.select(queryOptions);
  const saleDetails = saleDetailsSelector(state);

  return saleDetails?.data?.SaleDetails?.saleId || "";
}

export function getQueryOptions(state: EntityManagerState, queryCacheKey: string) {
  const originalArgs = (state.entityManager.queries[queryCacheKey]?.originalArgs ?? {}) as Record<
    string,
    string
  >;

  return {
    ahco: originalArgs.ahco,
    saleId: originalArgs.saleId,
    accessToken: originalArgs.accessToken,
  };
}

const blockUpdate = createAction(
  "comet/block/update",
  (
    block: Block,
    priorityBidLotId: string | null,
    accessToken: string,
    username: string,
    saleId: string
  ) => {
    return {
      payload: block,
      meta: {
        priorityBidLotId,
        accessToken,
        username,
        saleId,
      },
    };
  }
);
const lotUpdate = createAction<Lot>("comet/lot/update");
const assetUpdate = createAction<Asset>("comet/asset/update");
const saleDetailsUpdate = createAction<SaleDetails>("comet/sale/details/update");

function createEntityManagerMiddleware(): Middleware<
  CommonsDispatch,
  Partial<CommonsState> & Pick<CommonsState, "entityManager" | "auth">
> {
  return (store) => (next) => (action) => {
    if (isUiEntity(action)) {
      const uiEntity = getUiEntity(action);

      switch (uiEntity.uiEntityName) {
        case "Block":
          const queryArgs = getQueryOptions(store.getState(), action.payload.queryCacheKey);
          const block = uiEntity as Block;

          store.dispatch(
            blockUpdate(
              block,
              getPriorityBidLotId(store.getState(), block, queryArgs),
              queryArgs.accessToken,
              getUserName(store.getState(), queryArgs),
              getSaleId(store.getState(), queryArgs)
            )
          );

          break;
        case "Lot":
          store.dispatch(lotUpdate(uiEntity as Lot));
          break;
        case "Asset":
          store.dispatch(assetUpdate(uiEntity as Asset));
          break;
        case "SaleDetails":
          store.dispatch(saleDetailsUpdate(uiEntity as SaleDetails));
          break;
        default:
          break;
      }
    }

    return next(action);
  };
}

export { createEntityManagerMiddleware, blockUpdate, lotUpdate, assetUpdate, saleDetailsUpdate };
