import cometd, { SubscriptionHandle as CometSubscription, Listener } from "cometd";
import { UiEntityRecord } from "../services/EntityManagerServices/types";
import {
  IMessagingClient,
  MessagingClientType,
  MessagingConnectionStatus,
  MessagingConnectionStatusChangeListener,
  MessagingConnectionType,
  MessagingErrorListener,
  MessagingRefetchListener,
} from "../@types";
import { CollectionUtil } from "../utils";
import { EntityManagerQueryOptions } from "../services";

interface CometMessage {
  channel: string;
  data: {
    body: string;
  };
}

export class CometClient implements IMessagingClient<MessagingClientType.COMET> {
  private client: cometd.CometD;
  private subscriptions: CometSubscription[];
  private retryAttemptsRemaining: number;
  private timeout?: ReturnType<typeof setTimeout>;
  private cometConnectionType: MessagingConnectionType;

  sessionId: string;
  connectionStatus: MessagingConnectionStatus;
  servers: string[];

  onConnectionStateChange: MessagingConnectionStatusChangeListener;
  onError: MessagingErrorListener;
  onRefetch: MessagingRefetchListener;

  constructor() {
    this.client = new cometd.CometD();
    this.subscriptions = [];
    this.retryAttemptsRemaining = 10;
    this.cometConnectionType = "NEW";

    this.sessionId = "";
    this.connectionStatus = "new";
    this.servers = [];

    this.onConnectionStateChange = () => {};
    this.onError = () => {};
    this.onRefetch = () => {};
  }

  setServers(servers: string[]): void {
    this.servers = CollectionUtil.shuffle(servers);
  }

  initialize(
    _entityManagerQueryOptions: EntityManagerQueryOptions,
    onConnectionStateChange: MessagingConnectionStatusChangeListener,
    onError: MessagingErrorListener,
    onRefetch: MessagingRefetchListener
  ): Promise<void> {
    this.onConnectionStateChange = onConnectionStateChange;
    this.onError = onError;
    this.onRefetch = onRefetch;

    this.client.addListener("/meta/connect", ({ successful }) => {
      if (successful) {
        this.clearReconnectTimeout();

        if (this.connectionStatus === "disconnected") {
          this.client.batch(() => {
            this.subscriptions.forEach((subscription) => {
              this.client.resubscribe(subscription);
            });
          });

          // we set connected here in order to force refetch order with followAuction + other endpoints
          this.updateConnectionStatus("connected");

          this.onRefetch();
        }

        this.retryAttemptsRemaining = 10;
        this.updateConnectionStatus("connected");
      } else if (!successful && this.retryAttemptsRemaining > 0) {
        this.disconnect();
      } else {
        this.updateConnectionStatus("failed");
        this.onError({ errorCode: "RECONNECT_FAILED" });
      }
    });

    return this.configureClient();
  }

  private configureClient() {
    return new Promise<void>((resolve, reject) => {
      const server = this.getServer();

      this.client.configure({
        url: server,
        autoBatch: true,
        connectTimeout: 2100,
      });

      this.updateConnectionStatus(
        this.cometConnectionType === "NEW" ? "connecting" : "reconnecting"
      );

      // reject configure client promise if we don't handshake within 2s
      const rejectTimeout = setTimeout(() => {
        reject();
      }, 2000);

      this.client.handshake(({ successful, clientId = "" }) => {
        if (successful) {
          clearTimeout(rejectTimeout);
          this.sessionId = clientId;

          resolve();
        }

        this.clearReconnectTimeout();
      });
    });
  }

  private getServer(): string {
    const server = this.servers.shift();

    if (!server) {
      throw new Error("No comet servers defined");
    }

    this.servers.push(server);

    return server;
  }

  disconnect() {
    if (this.isConnected()) {
      this.cometConnectionType = "RECONNECT";
    }

    this.updateConnectionStatus("disconnected");

    if (!this.timeout) {
      this.retryAttemptsRemaining--;

      this.timeout = setTimeout(async () => {
        try {
          await this.configureClient();
        } catch (error) {
          this.clearReconnectTimeout();
        }
      }, 2000);
    }
  }

  isConnected(): boolean {
    return this.connectionStatus === "connected";
  }

  publish(data: object, channel?: string, onMsg?: Listener): void {
    if (channel) {
      this.client.publish(channel, data, onMsg);
    }
  }

  subscribe(
    _uiEntityName: string,
    channel: string,
    _queryOptions: EntityManagerQueryOptions,
    listener: (data: UiEntityRecord) => void
  ): CometSubscription | Promise<CometSubscription> {
    const subscriptionHandle = this.client.subscribe(channel, (message: CometMessage) => {
      const uiEntityRecord = JSON.parse(message.data.body);

      listener(uiEntityRecord);
    });

    this.subscriptions.push(subscriptionHandle);

    return subscriptionHandle;
  }

  unsubscribe(subscription: CometSubscription): void {
    if (this.isConnected()) {
      this.client.unsubscribe(subscription);

      this.subscriptions = this.subscriptions.filter((sub) => sub.id !== subscription.id);
    } else {
      setTimeout(() => {
        this.unsubscribe(subscription);
      }, 250);
    }
  }

  createChannelName(channel: string): string {
    return `/topic/${channel}`;
  }

  updateConnectionStatus(connectionStatus: MessagingConnectionStatus): void {
    if (this.connectionStatus !== connectionStatus) {
      this.connectionStatus = connectionStatus;
      this.onConnectionStateChange(connectionStatus);
    }
  }

  getConnectionType(): MessagingConnectionType {
    return this.cometConnectionType;
  }

  private clearReconnectTimeout(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }
  }
}
