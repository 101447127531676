import { Lot, SellingType, ReviewStatus } from "../services/EntityManagerServices/types";

export class LotUtil {
  static isUnsold(lot?: Lot): boolean;
  static isUnsold(lotStatus?: string): boolean;
  static isUnsold(arg?: Lot | string): boolean;
  static isUnsold(arg?: Lot | string): boolean {
    if (typeof arg === "string") {
      return arg === "UNSOLD";
    }

    return arg?.status === "UNSOLD";
  }

  static isSold(lot?: Lot): boolean;
  static isSold(lotStatus?: string): boolean;
  static isSold(arg?: Lot | string): boolean;
  static isSold(arg?: Lot | string): boolean {
    if (typeof arg === "string") {
      return arg === "SOLD";
    }

    return arg?.status === "SOLD";
  }

  static isWithdrawn(lot?: Lot): boolean;
  static isWithdrawn(lotStatus?: string): boolean;
  static isWithdrawn(arg?: Lot | string): boolean;
  static isWithdrawn(arg?: Lot | string): boolean {
    if (typeof arg === "string") {
      return arg === "WITHDRAWN";
    }

    return arg?.status === "WITHDRAWN";
  }

  static isNoSale(lot?: Lot): boolean;
  static isNoSale(lotStatus?: string): boolean;
  static isNoSale(arg?: Lot | string): boolean;
  static isNoSale(arg?: Lot | string): boolean {
    if (typeof arg === "string") {
      return arg === "NOSALE";
    }

    return arg?.status === "NOSALE";
  }

  static isIfSale(lot?: Lot): boolean;
  static isIfSale(lotStatus?: string): boolean;
  static isIfSale(arg?: Lot | string): boolean;
  static isIfSale(arg?: Lot | string): boolean {
    if (typeof arg === "string") {
      return arg === "IFSALE";
    }

    return arg?.status === "IFSALE";
  }

  static inTerminalState(lot?: Lot): boolean;
  static inTerminalState(lotStatus?: string): boolean;
  static inTerminalState(arg?: Lot | string): boolean;
  static inTerminalState(arg?: Lot | string): boolean {
    return (
      LotUtil.isSold(arg) ||
      LotUtil.isWithdrawn(arg) ||
      LotUtil.isIfSale(arg) ||
      LotUtil.isNoSale(arg)
    );
  }

  static inActiveState(lot?: Lot): boolean;
  static inActiveState(lotStatus?: string): boolean;
  static inActiveState(arg?: Lot | string): boolean;
  static inActiveState(arg?: Lot | string): boolean {
    return LotUtil.isUnsold(arg);
  }

  static isByUnit(lot?: Lot): boolean;
  static isByUnit(sellingType?: SellingType): boolean;
  static isByUnit(arg?: Lot | SellingType): boolean {
    if (typeof arg === "string") {
      return arg === "BY_UNIT";
    }

    return arg?.sellingType === "BY_UNIT";
  }

  static userHasWonLot(biddingUser?: string | null, currentUser?: string | null): boolean;
  static userHasWonLot(lot: Lot, userName?: string | null): boolean;
  static userHasWonLot(arg1?: Lot | string | null, arg2?: string | null): boolean {
    if (!(arg1 && arg2)) {
      return false;
    }

    if (typeof arg1 === "string") {
      return arg1 === arg2;
    }

    return LotUtil.isSold(arg1) && arg1?.highBid?.userName === arg2;
  }

  static anySold(lots: Lot[]): boolean {
    return lots.some((lot) => this.isSold(lot));
  }

  static isReviewRequested(lot?: Lot): boolean;
  static isReviewRequested(reviewStatus?: ReviewStatus): boolean;
  static isReviewRequested(arg?: Lot | ReviewStatus): boolean;
  static isReviewRequested(arg?: Lot | ReviewStatus): boolean {
    return this.isReviewPending(arg) || this.isReviewVerified(arg) || this.isReviewCorrected(arg);
  }

  static isReviewPending(lot?: Lot): boolean;
  static isReviewPending(reviewStatus?: ReviewStatus): boolean;
  static isReviewPending(arg?: Lot | ReviewStatus): boolean;
  static isReviewPending(arg?: Lot | ReviewStatus): boolean {
    if (typeof arg === "string") {
      return arg === "PENDING";
    }

    return arg?.reviewStatus === "PENDING";
  }

  static isReviewVerified(lot?: Lot): boolean;
  static isReviewVerified(reviewStatus?: ReviewStatus): boolean;
  static isReviewVerified(arg?: Lot | ReviewStatus): boolean;
  static isReviewVerified(arg?: Lot | ReviewStatus): boolean {
    if (typeof arg === "string") {
      return arg === "VERIFIED";
    }

    return arg?.reviewStatus === "VERIFIED";
  }

  static isReviewCorrected(lot?: Lot): boolean;
  static isReviewCorrected(reviewStatus?: ReviewStatus): boolean;
  static isReviewCorrected(arg?: Lot | ReviewStatus): boolean;
  static isReviewCorrected(arg?: Lot | ReviewStatus): boolean {
    if (typeof arg === "string") {
      return arg === "CORRECTED";
    }

    return arg?.reviewStatus === "CORRECTED";
  }
}
