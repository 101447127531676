import { ProxyBid } from "../services/EntityManagerServices/types";

export class UserUtil {
  static hasBidOnLot(lotsWithBids: string[], lotNumber: string): boolean {
    return lotsWithBids.includes(lotNumber);
  }

  static hasMaxBidOnLot(maxBids: Record<string, ProxyBid>, lotNumber: string): boolean {
    return Reflect.has(maxBids, lotNumber);
  }
}
