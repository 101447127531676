import { BaseQueryEnhancer } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { HostnameUtil } from "../../../utils";
import { serversSelector, Resources, discoveryService } from "../../DiscoveryService";
import { PartialState } from "../../../@types";

export interface ServiceProviderOptions {
  serviceProvider: "entityManager" | "services" | "terminology" | "cloudServices" | "cubed";
}

type DiscoveryStore = PartialState<typeof discoveryService>;

export const queryWithServiceDiscovery: BaseQueryEnhancer<
  unknown,
  ServiceProviderOptions | object,
  ServiceProviderOptions
> = (baseQuery, options) => async (args, baseQueryApi, extraOptions) => {
  const partialUrl = typeof args === "string" ? args : args.url;

  const services: Resources = serversSelector(baseQueryApi.getState() as DiscoveryStore);

  const serviceProviderUrl: string = services[options.serviceProvider];

  let url = `${serviceProviderUrl}/${partialUrl}`;

  if (options.serviceProvider === "terminology" && Array.isArray(serviceProviderUrl)) {
    const terminologyHostname = await HostnameUtil.getAccessibleHostname(
      serviceProviderUrl,
      "health"
    );

    url = `${terminologyHostname}/${partialUrl}`;
  }

  const enhancedArgs = typeof args === "string" ? url : { ...args, url };

  // TODO: Figure this out...
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return baseQuery(enhancedArgs, baseQueryApi, extraOptions) as any;
};
