import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { ConnectionState } from "../../services/EntityManagerServices/types";
import { REHYDRATE } from "redux-persist";
import { PartialState } from "../../@types";

export interface AttendParams {
  accessToken: string;
  ahco: string;
  clientEventId: string;
  displayName: string;
  displayLocation: string;
  lang: string;
  marketPlace: string;
  connectionState: ConnectionState;
  playerVersion?: string;
}

export type MultiAttendParams = Omit<AttendParams, "accessToken" | "clientEventId"> & {
  accessToken: string | string[];
  clientEventId: string | string[];
};

export interface Auth extends AttendParams {
  isLoggedIn: boolean;
}

export type UiFlavor = "rbaR" | "techR" | "accR" | "ams" | "automotive" | "amcR" | "";

type AuthState = {
  attends: Array<Auth>;
  uiFlavor: UiFlavor;
  selectedAccessToken?: string;
};

export interface ConnectionStateChangeParams {
  accessToken: string;
  connectionState: ConnectionState;
  isLoggedIn: boolean;
}

const initialState: AuthState = { attends: [], uiFlavor: "" };

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    attend: (state, action: PayloadAction<AttendParams>) => {
      const { clientEventId, marketPlace, ...attend } = action.payload;

      if (
        !state.attends.some((existingAttend) => attend.accessToken === existingAttend.accessToken)
      ) {
        state.attends.push({
          ...attend,
          clientEventId: clientEventId,
          isLoggedIn: true,
          marketPlace,
        });

        state.selectedAccessToken = attend.accessToken;
      }
    },
    selectAccessToken: (state, action: PayloadAction<string>) => {
      state.selectedAccessToken = action.payload;
    },
    setUiFlavor: (state, action: PayloadAction<UiFlavor>) => {
      state.uiFlavor = action.payload;
    },
    setConnectionState: (state, action: PayloadAction<ConnectionStateChangeParams>) => {
      state.attends = state.attends.map((attend) =>
        attend.accessToken === action.payload.accessToken
          ? {
              ...attend,
              connectionState: action.payload.connectionState,
              isLoggedIn: action.payload.isLoggedIn,
            }
          : attend
      );
    },
    removeAttend: (state, action: PayloadAction<string>) => {
      state.attends = state.attends.filter((attend) => attend.accessToken !== action.payload);

      if (state.selectedAccessToken === action.payload) {
        const loggedInAttend = state.attends.find((attend) => attend.isLoggedIn);

        if (loggedInAttend) {
          state.selectedAccessToken = loggedInAttend.accessToken;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, (_state, action) => {
      // @ts-expect-error - No type information available
      // TODO: Replace redux-persist as it seems to no longer be maintained
      const auth = action.payload?.auth;

      if (auth) {
        auth.attends.forEach((attend: Auth) => {
          attend.isLoggedIn = true;
          attend.connectionState = "TAKEOVER";
        });

        _state = auth;
      }
    });
  },
});

type AuthReducerState = PartialState<typeof auth>;

const selectAuth = (state: AuthReducerState) => state.auth;
const selectAttends = (state: AuthReducerState) => state.auth.attends;
const selectSaleIds = createSelector([selectAttends], (attends) =>
  attends.filter((attend) => attend.isLoggedIn).map((attend) => attend.clientEventId)
);

const selectCurrentAccessToken = createSelector([selectAuth], (auth) => auth.selectedAccessToken);

const selectCurrentAttend = createSelector(
  [selectAttends, selectCurrentAccessToken],
  (attends, accessToken): Auth =>
    attends.find((attend) => attend.accessToken === accessToken) ?? ({} as Auth)
);

const selectByAccessToken = (_state: AuthReducerState, accessToken: string | undefined) =>
  accessToken;

const selectAttendByAccessToken = createSelector(
  [selectAttends, selectCurrentAttend, selectByAccessToken],
  (attends, currentAttend, accessToken) => {
    return attends.find((attend) => attend.accessToken === accessToken) ?? currentAttend;
  }
);

const selectUiFlavor = createSelector([selectAuth], (authState) => authState.uiFlavor);

const selectAttendByConnectionState = createSelector([selectAttends], (attends) =>
  attends.find((attend) => attend.connectionState !== undefined)
);

export const { name: authReducerPath, reducer: authReducer } = auth;
export const {
  attend,
  selectAccessToken,
  setUiFlavor,
  setConnectionState,
  removeAttend,
} = auth.actions;
export {
  selectCurrentAccessToken,
  selectSaleIds,
  selectAttendByAccessToken,
  selectCurrentAttend,
  selectUiFlavor,
  selectAttends,
  selectAttendByConnectionState,
};

export function buildAttendParams(params: MultiAttendParams): AttendParams[] {
  const {
    accessToken,
    ahco,
    clientEventId,
    displayName,
    displayLocation,
    lang,
    marketPlace,
  } = params;

  if (Array.isArray(accessToken)) {
    return accessToken.map(
      (token, index) =>
        ({
          accessToken: token,
          ahco,
          clientEventId: clientEventId[index],
          displayName,
          displayLocation,
          lang,
          marketPlace,
        } as AttendParams) // TODO: Don't like this cast, but we need it cause of Comet's connectionState
    );
  } else {
    return [
      {
        accessToken,
        ahco,
        clientEventId: Array.isArray(clientEventId) ? clientEventId[0] : clientEventId,
        displayName,
        displayLocation,
        lang,
        marketPlace,
      } as AttendParams, // TODO: Don't like this cast, but we need it cause of Comet's connectionState
    ];
  }
}
