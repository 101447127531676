import { Component, ReactNode } from "react";
import { ErrorPage } from "./ErrorPage";

interface ErrorBoundaryState {
  error?: Error;
}

export interface ErrorBoundaryProps {
  children: ReactNode;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = {
    error: undefined,
  };

  componentDidCatch(error: Error) {
    this.setState({ error });
  }

  render() {
    if (Boolean(this.state.error)) {
      return <ErrorPage error={this.state.error?.message} />;
    }

    return this.props.children;
  }
}
